<template>

    <div>

        <section v-html="privacyContent"></section>
    </div>
</template>



<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            privacyContent:"",
            getPrivacyUrl: "",
            privacyType: "",
            getLangname:""
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)
            const getConfigData = getConfigDataFromLocalStorage();
            let locale = this.getLangname
            if (getConfigData) {
                let siteContentData = getConfigData.payload.portal_configuration.site_content_data[locale]
                this.getPrivacyUrl = siteContentData.privacypolicy.url
                this.privacyType = siteContentData.privacypolicy.type
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getPrivacyUrl, 'head...about..2..')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getPrivacyUrl, {
                headers: {
                    "Content-Type": this.privacyType,
                },
            })
                .then((response) => {

                    this.privacyContent = response.data
                    // console.log(response.data, "getPrivacyUrlrgetPrivacyUrlrgetPrivacyUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
         updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.getLangname,'getcontententUrl...1');
            this.getConfig();
        },
    },
    watch: {
        "$i18n.locale": {
        handler() {
            this.updateContent();
        },
        },
    },

    created() {
            // this.getConfig();
            this.getConfigFunc();
    },
    mounted(){
        this.updateContent();
    }
}

</script>