<template>
    <div class="content-section">
        <!-- flight result page - oneway start -->
        <div class="review-flight py-5">
            <div class="container">
                <div class="row loginCard">
                    <div class="col-12 col-md-6 offset-md-3">
                        <!-- travel insurance section -->
                        <div class="theme-box-shadow theme-border-radius theme-bg-white p-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="flex-shrink-0">
                                    <div class="theme-bg-primary p-3 rounded-circle">
                                        <i class="bi bi-unlock lh-1 fs-4" style="color:white"></i>
                                    </div>

                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="fs-4 fw-bold">{{ $t("loginContents.signIn") }}</span>
                                    <p class="font-medium mb-0 theme-text-accent-one">{{ $t("loginContents.subHaed") }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12">

                                <v-form ref="form" v-if="loginshow" v-model="valid" @submit.prevent="loginsubmitForm">
                                <!-- <form class="needs-validation" novalidate> -->
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">{{ $t("loginContents.labels.emailAddress") }}</label>
                                        <v-text-field v-model="loginuser.userEmail" :rules="eMail" 
                                    variant="outlined" :placeholder="this.$t('loginContents.placeholder.email')"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">{{ $t("loginContents.labels.password") }}</label>
                                        <v-text-field v-model="loginuser.userPass" :rules="uPass"
                                    :placeholder="this.$t('loginContents.placeholder.password')" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                    </div>
                                    <div class="mb-3 form-check d-flex justify-content-between align-center">
                                        <div>
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                        <label class="form-check-label font-small mt-1" for="exampleCheck1">{{ $t("loginContents.labels.rememberMe") }}</label>
                                        </div>

                                            <div>
                                                <span style="position: relative;top: 8px;">
                                            <p class="forgotStyle" @click="this.loginshow = false, this.forgotshow = true"
                                            style="text-decoration:none;">
                                                {{ $t("loginContents.buttonsLink.forgotPassword") }}</p>
                                        </span>
                                            </div>
                                    </div>
                                    <div class="mb-3 d-flex align-center">
                                        <button type="submit" class="btn btn-effect btn-book px-4 px-md-5 min-h44" :class="{'pe-none' : loadLogin}">
                                            <v-progress-circular v-if="loadLogin" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !loadLogin ? $t("loginContents.buttonsLink.login") : '' }}</button>
                                        
                                        <span class="font-medium">
                                            <span class="ms-3 me-2 text-uppercase">{{ $t("loginContents.buttonsLink.or") }}</span>
                                            <a href="/register" class="">{{ $t("loginContents.buttonsLink.createAccount") }}</a>
                                        </span>
                                    </div>
                                <!-- </form> -->

                                </v-form>




                                <v-form ref="form" v-if="forgotshow" v-model="valid" @submit.prevent="submitResetForm">
                                <!-- <form class="needs-validation" novalidate> -->
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">{{ $t("loginContents.labels.emailAddress") }}</label>
                                        <!-- <v-text-field v-model="loginuser.userEmail" :rules="eMail" 
                                    variant="outlined" placeholder="Enter your Email"
                                    style="height:55px;font-size: 15px !important"></v-text-field> -->
                                    <v-text-field v-model="resetDetail.resetMail" :rules="eMail" label="Email"
                                    variant="outlined" :placeholder="$t('loginContents.labels.emailAddress')"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                    </div>


                                    <!-- <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Password</label>
                                        <v-text-field v-model="loginuser.userPass" :rules="uPass"
                                    placeholder="Enter your password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="height:55px;font-size: 15px !important"></v-text-field>
                                    </div> -->


                                    <div class="mb-3 form-check d-flex justify-content-between align-center">
                                        <div>
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                        <label class="form-check-label font-small mt-1" for="exampleCheck1">{{ $t("loginContents.labels.rememberMe") }}</label>
                                        </div>

                                            <div>
                                                <span style="position: relative;top: 15px;">
                                            <p class="f-size-13">{{ $t("loginContents.labels.rememberPass") }}
                                                <span class="forgotStyle" @click="this.loginshow = true, this.forgotshow = false">{{ $t("loginContents.buttonsLink.login") }}</span>
                                            </p>
                                            
                                        </span>
                                            </div>
                                    </div>
                                    <div class="mb-3 d-flex justify-center align-center">
                                        <button type="submit" class="btn btn-effect btn-book px-4 px-md-5 min-h44" :class="{'pe-none' : loadforget}">
                                            <v-progress-circular v-if="loadforget" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !loadforget ? $t("loginContents.buttonsLink.submit") : '' }}</button>
                                        
                                        <span class="font-medium">
                                            <span class="ms-3 me-2 text-uppercase">{{ $t("loginContents.buttonsLink.or") }}</span>
                                            <a href="/register" class="">{{ $t("loginContents.buttonsLink.createAccount") }}</a>
                                        </span>
                                    </div>
                                <!-- </form> -->

                                </v-form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <v-dialog v-model="errorMsg1">
    <v-card class="m-auto px-2 py-4" style="width:300px !important">
      <div class="d-flex justify-center flex-wrap text-success">
        <h5>{{ errorMsgData }}...</h5>
      </div>
      <br/>

     <v-btn @click="closeBtn()" color="#94c20e" width="110px" class="m-auto text-white">Try Again</v-btn>
    </v-card>
  </v-dialog>

    <v-dialog v-model="successPop">
    <v-card class="m-auto px-2 py-4" style="width:300px !important">
      <div class="d-flex justify-center flex-wrap text-success">
        <h5>{{ Successmsg }}...</h5>
      </div>
      <br/>

     <v-btn @click="closeBtn" to="/" color="#94c20e" width="90px" class="m-auto text-white">Close</v-btn>
    </v-card>
  </v-dialog>


    <!-- <button @click="showToast()" class="btn btn-effect btn-book px-5 min-h58">Buttommmm</button> -->


    <!-- <div class="toast" :class="isActive ? 'active' : ''">
        <div class="toast-content">
          <i class="bi bi-check toast-check"></i>
          <div class="message">
            <span class="message-text text-1">Success</span>
            <span class="message-text text-2">Your {{ Successmsg }}</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div> -->


    <!-- <div class="toast" :class="isNotActive ? 'active border-danger' : ''">
        <div class="toast-content">
          <i class="bi bi-x-lg toast-check" :class="isNotActive ? 'bg-danger' : 'bg-danger'"></i>
          <div class="message">
            <span class="message-text text-1">{{ ErrorMessage }}</span>
            <span class="message-text text-2">Please Enter Correct Details</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isNotActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div> -->

    <!-- <div class="toast">
    <div class="toast-content">
      <i class="uil uil-check toast-check"></i>
      <div class="message">
        <span class="message-text text-1">Success</span>
        <span class="message-text text-2">Your changes has been saved</span>
      </div>
    </div>
    <i class="uil uil-multiply toast-close"></i>
    <div class="progress"></div>
  </div> -->


</template>


<script>
// import registerCom from '@/components/registerCom.vue';
import axios from 'axios'

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    components: {
        // registerCom
    },
    data() {
        return {
            loginbox: true,
            registerCom: false,
            terms: false,
            visible: false,
            loadLogin: false,
            btnDisabled: false,
            errorMsg: false,

            loginshow: true,
            forgotshow: false,
            emailsentMsg: false,
            resetshow: false,
            loadforget: false,
            errforget: false,
            userName: "",
            userEmail: "",
            errorMsg1:false,
            errorMsgData:"",

            customKey: "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
            customMail: "sarath@gmail.com",



            loginuser: {
                userEmail: "",
                userPass: "",
                confirmPass: "",
                dataPass : false,
            },

            resetDetail: {
                resetMail: "",
            },

            eMail: [
                v => !!v || 'Please enter your email',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            loginReq: {
                "request_type": "user_login",
                "email": "",
                "password": "",
                "portal_id": "",
                "token": "Tc7RQaX79Hrx5CFK",
                "portal_domain":"",
            },

            forgetDetails: {
                "request_type": "forgot_password",
                "email": "irwogms@gmail.com",
                "user_id": "443355",
                "portal_id": "11111"
            },

            loginUrl: "",
            forgotPassword: "",
            portal_domain:"",




            // Register Code ----->

            valid: false,

            registerbox: false,
            loginSection: true,
            // terms: false,
            // visible: false,

            regForm: true,
            regMsg: false,
            registerMsg: "",
            loadRegister: false,
            // btnDisabled: false,

            personal: {
                username: "",
                email: "",
                password: "",
                rePassword: ""
            },



            // userName: [
            //     v => !!v || 'Please enter your Username',
            //     v => (v.length >= 3) || 'Name must be greater than 3 ',
            // ],

            // eMail: [
            //     v => !!v || 'E-mail is required',
            //     v => /.+@.+/.test(v) || 'E-mail must be valid',
            // ],

            passWord: [
                v => !!v || 'Please enter your Password',
                // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
                // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
                // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
            ],
            repassWord: [
                v => !!v || 'Please enter your Re-password',
                v => v === this.personal.password || 'Passwords do not match'
            ],

            registerUrl: "",
            portalId:"",
            portalGroupId:"",


            // Register Code End ----->


            isActive: false,
            isNotActive: false,
            Successmsg:"",
            successPop:false,
        }
    },


    methods: {

         closeBtn(){
            setInterval(() => {
                location.reload();
            }, 500);
        },

        showToast() {
      this.isActive = true;
      setTimeout(() => {
        if(this.isActive){
            this.isActive = false;
        console.log(this.isActive,'this.isActivethis.isActive....2')

        }
      }, 5000);
    },
    closeToast() {
      this.isActive = false;
      this.isNotActive = false;
    },

        signUp() {
            this.registerbox = true
            this.loginbox = false
        },


        onClickOutside() {
            this.loginbox = false
            console.log(this.loginbox, "5555555")
        },

        loginsubmitForm() {


            console.log(this.valid,'this.validthis.valid')
            if (this.valid) {

            this.loadLogin = true;

                console.log(this.valid, 'vvvvaaaallll')

                this.btnDisabled = true

                this.loginReq.email = this.loginuser.userEmail
                this.loginReq.password = this.loginuser.userPass
                this.loginReq.portal_id = this.portalId
                this.loginReq.portal_domain = this.portal_domain
                console.log(this.loginReq, 'kkkk.....')

                axios.post(this.loginUrl, this.loginReq, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log('Response:', response.data);
                        localStorage.setItem("loginuser", JSON.stringify(response.data))
                        this.errorMsg = false

                        this.Successmsg = response.data.message;
                        this.successPop = true;

                        // console.log(response.data.user,'.....Response...:');

                        // this.forgetDetails.user_id=response.data.user.user_id

                        // console.log(this.forgetDetails.user_id,'.....Response..11111.:');

                        this.loadLogin = false;
                        this.loginbox = false;

                        this.isActive =  true;

                        // localStorage.setItem('value', true);
                        // JSON.stringify(localStorage.setItem('loginDatas', this.loginuser))
                        let logindata = JSON.stringify(this.loginuser);
                        localStorage.setItem("loginDatas", logindata);

                        setTimeout(()=>{
                            this.isActive =  false;
                            // this.$router.push('/');

                            // setInterval(() => {
                            //     location.reload();
                            // }, 500);
                            
                        },5000)

                    })
                    .catch(error => {
                        console.log(this.valid, 'vvvvaaaallll....1')
                        console.error('Error:', error.response ? error.response.data : error.message);
                        console.log(error,'errorRezsponseeee')
                        // this.errorMsg = true
                        // this.isNotActive = true
                        this.errorMsg1 = true
                        this.errorMsgData = error.response.data.message;
                        this.loginuser.userEmail = null
                        this.loginuser.userPass = null


                        setTimeout(()=>{
                            this.isNotActive =  false;
                        },5000)
                        // alert("Incorrect email or password...")
                        this.loadLogin = false
                        this.btnDisabled = false
                    });
            }

        },

        submitResetForm() {
            // this.loginbox = false;

            if (this.valid) {
                // this.emailsentMsg = true
                // this.forgotshow = false
                this.loadforget = true

                this.forgetDetails.email = this.resetDetail.resetMail
                this.forgetDetails.user_id = this.resetDetail.resetMail
                this.forgetDetails.portal_id = this.portalId,
                this.forgetDetails.portal_domain = this.portal_domain,


                console.log(this.forgetDetails, 'reqqqqqqqqqq')

                axios.post(this.forgotPassword, this.forgetDetails, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        console.log(response, 'rrrreeeeeessssssss.....')
                        this.loadforget = false
                        if (response.data.forgot_password_response == 'success') {
                            // this.forgotshow = false
                            this.emailsentMsg = true

                            this.Successmsg = response.data.msg

                            this.isActive =  true;

                            setTimeout(()=>{
                            this.isActive =  false;
                            this.loginshow = true;

                            // this.$router.push('/');
                            // setInterval(() => {
                            //     location.reload();
                            // }, 500);
                        },5000)
                        }

                        else if (response.data.forgot_password_response == 'fail') {
                            this.forgotshow = true
                            this.emailsentMsg = false
                            this.loadforget = false
                            this.errforget = true
                        }
                    })
                    .catch(errors => {
                        console.log(errors, 'eeerrrrooorrr')
                        this.forgotshow = true
                    })

            }
        },

        closeLoginBox() {
            this.loginbox = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
            console.log(this.loginbox, "1111111");
        },

        getConfig() {
            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData1 = JSON.parse(portalLocal)
            const getConfigData1 = getConfigDataFromLocalStorage();
            if (getConfigData1) {
                this.loginUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2clogin
                this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
                this.forgotPassword = getConfigData1.payload.portal_configuration.API_endpoints.forgot_password
                this.portalId = getConfigData1.payload.portal_configuration.portal_id
                this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
                this.portalGroupId = getConfigData1.payload.portal_configuration.portal_group_id

                console.log(this.getConfigData1, 'head...')
                console.log(this.loginUrl, 'head...reg..1..')
            }
        },

        // Regiter code

        signInAgain() {
            //   this.loginSection = true 
            this.loginbox = true
            this.registerbox = false
            console.log(this.loginSection, this.registerbox, "Workinglogin....")
        },

        closeRegisterBox() {
            this.registerbox = false;
            this.$emit('close'); // Emit the 'close' event to the parent component
            console.log(this.registerbox, '00000')
        },

    },

    computed: {
        progressColor() {
         return this.isActive ? '#40f467' : '#ff0000';
        },
    },

    created() {
        this.getConfig();
    }
}
</script>



<style scoped>

@import "@/assets/main.css";


/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}

>>>.v-label--clickable {
    cursor: pointer;
    font-size: 12px;
}

>>>.v-input__details {
    display: block !important;
}

.forgotStyle {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
    text-align: end;
}


>>>.loginCard .v-input__control {
  height: 45px;
}

>>>.loginCard .v-field__field {
  height: 45px;
}

>>>.loginCard .v-field--focused {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
  border: none !important;
}
>>>.loginCard .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
}

>>>.loginCard .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-top: 8px;
}


>>>.loginCard .v-field__input input::placeholder {
    position: relative;
    top: -10px;
}

>>>.loginCard .v-autocomplete .v-field__input input::placeholder {
    position: relative;
    top: 0px;
    
}









/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}



/* Register code */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

>>>.v-input__details {
    display: block !important;
}


/* .v-checkbox .v-input__details {
  display: none;
} */

/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}













body{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  overflow: hidden;
}

.toast{
  position: absolute;
  top: 80px;
  right: 10px;
  border-radius: 6px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-left: 8px solid #40f467;
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}
.toast.active{
  transform: translateX(0);
}
.toast-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-check{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: #40f467;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}
.message{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.message-text{
  font-size: 20px;
  font-weight: 600;
}
.text-1{
  color: #333;
}
.text-2{
  color: #666;
  font-weight: 400;
  font-size: 13px;
}
.toast-close{
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}
.toast-close:hover{
  opacity: 1;
}
.progress{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #ddd;
}

.progress::before{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: var(--progress-color, #40f467);
  background-color: var(--progress-color, #ff0000);
}
.progress.active::before{
  animation: progress 5s linear forwards;
}

.toast:not(.show) {
    display: block;
}


@keyframes progress {
  100%{
    right: 100%;
  }
}
.toast-btn{
  padding: 10px 40px;
  font-size: 20px;
  outline: none;
  border: none;
  background-color: #40f467;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.toast-btn:hover{
  background-color: #0fbd35;
}
.px-5{
    padding-left:3rem !important;
    padding-right:3rem !important;
}
</style>
