import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import 'boxicons';
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router/index.js";
import PrimeVue from 'primevue/config';
import i18n from '@/plugins/i18n';

loadFonts()
createApp(App)
.use(i18n)
.use(router)
.use(vuetify)
.use(PrimeVue)
.use(i18n)
.mount('#app')
