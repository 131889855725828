<template>

    <div class="p-3" style="background-color:#ffffffd9">
        <section v-html="aboutContent"></section>
  </div>
</template>


<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            aboutContent:"",
            getAboutUrl: "",
            aboutType: "",
            getLangname:""
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)

            const getConfigData = getConfigDataFromLocalStorage();
            let locale = this.getLangname
            if (getConfigData) {
                const siteContentData = getConfigData.payload.portal_configuration.site_content_data;

                if (siteContentData[locale] && siteContentData[locale].aboutus) {
                    this.getAboutUrl = siteContentData[locale].aboutus.url;
                    this.aboutType = siteContentData[locale].aboutus.type;
                }
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getAboutUrl, 'head...about..2..')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getAboutUrl, {
                headers: {
                    "Content-Type": this.aboutType,
                },
            })
                .then((response) => {

                    this.aboutContent = response.data
                    console.log(response.data, "getAboutUrlrgetAboutUrlrgetAboutUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
        updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.getLangname,'getcontententUrl...1');
            this.getConfig();
        },
    },
    watch: {
        "$i18n.locale": {
        handler() {
            this.updateContent();
        },
        },
    },

    created() {
            this.getConfig();
            this.getConfigFunc();
    },
    mounted(){
        this.updateContent();
    }
}

</script>