export default {
    headerContents: {
      helpSupport: "Help & Support",
      chatSupport: "Chat Support",
      requestForm: "Request Form",
      account: "Account",
      login: "Login",
      register: "Register",
      myBookings: "My Bookings",
      viewBooking: "View Booking",
      myProfile: "My Profile",
      logOut: "Log Out",
      findMyTrip: "View My Trip",
    },
    homePageContent: {
      welcomeMsg: {
        text1: "Let's Travel And Explore Destination.",
        text2: "Life is unpredictable, and we understand that plans might change. Enjoy flexible booking options, so you can reschedule or modify your trip with ease.",
      },
      hightlightContent: {
        heading1: "Find Top Destinations",
        subHead1: "Travel is a transformative and enriching experience that allows individuals to explore new destinations, cultures, and landscapes.",
        heading2: "Welcome To",
        subHead2: "And Find Some Amazing Tour.",
        heading3: "Ultimate Travel Experience",
        subHead3: "Our carefully curated experiences guarantee you explore the world's most captivating places in style and comfort.",
        heading4: "Favorite Tour Packages",
        subHead4: "Explore our top tour packages for exceptional experiences and great value. Whether you seek adventure or relaxation, find the perfect trip.",
        heading5: "What Zetsfly Offers",
        subHead5: "Experience seamless travel with our local guides and passionate experts. Book confidently with our satisfaction guarantee and personalized service.",
      },
      contentSection: {
        economy: "Economy",
        premiumeconomy: "Premium Economy",
        business: "Business",
        firstclass: "First Class",
        perPerson: "Per Person",
        bookNow: "BOOK NOW",
        submit: "Submit",
        letstravel: "Let’s Travel",
        familytour: "Family Tour",
        offer: "25% off",
        booktrip: "Book A Trip",
        supertravel: "Super Travel",
        nexttrip: "Your next trip",
        happytraveler: "Happy Traveler",
        toursuccess: "Tours Success",
        positivereview: "Positives Review",
        travelguide:"Travel Guide",
        startingFrom: "Starting From",
        content1:"Whatever your summer looks like, bring yourown heat with up to 25% off Lumin Brand",
        bestFlight: "Best Flights",
        subContent1:"Travel Alerts and Registration",
        tourGuide: "Tour Guide",
        subContent2:"Travel Documentation",
        subContent3: "Health and Medical Security",
        subContent4: "Transportation Security",
        subContent5: "Health and Medical Security",
        viewDestination: "View All Destination",
        viewPackages: "View All Package"
      },
    },
    formsContents: {
      modifyContent: {
        heading: "FIND CHEAP TICKETS-SAVE BIG",
      },
      labels: {
        flyFrom: "Flying From",
        subDep: "Where do you want to fly from?",
        flyTo: "Flying To",
        subArr: "Where do you want to fly to?",
        travelDate: "Travel Date",
        leaveFrom: "Leaving From",
        goingTo: "Going To",
        fromlabel: "From",
        tolabel: "To",
        datelabel: "Date",
        departure: "Departure",
        origin:"Origin",
        destination:"Destination",
        addDate: "Add Date",
        addCity: "Add City",
        departDate: "Depart Date",
        returnDate: "Return Date",
        return: "Return",
        arrival: "Arrival",
        flight: "Flight",
        email: "Email",
        phoneNumber: "Phone Number",
        recentSearch: "Recent searches",
        enterEmail: "Enter Email Address",
      },
      options: {
        oneWay: "One-way",
        return: "Return",
        roundTrip: "ROUND-TRIP",
        multiCity: "Multi-city",
        adult: "Adult",
        child: "Child",
        children: "Children",
        infant: "Lap Infant",
        infants: "Lap Infants",
        years: "yrs",
        classType: "Class Type",
        passenger: "Passenger",
        passengers: "Passenger(s)",
        travellers: "Traveller(s)",
        cabin: "Cabin",
        economy: "Economy",
        premiumEconomy: "Premium Economy",
        business: "Business",
        firstClass: "First Class",
        done: "Done",
        addMore: "Add More",
        addCity: "Add City",
        clearAll: "Clear All",
        search: "Search",
        go: "Go",
      },
      errorMsg: {
        departReq: "Departure is required",
        arrivalReq: "Arrival is required",
        depdateReq: "Dep. Date is required",
        retdateReq: "Return. Date is required",
        enterMinimum: "Enter minimum 3 letters",
      },
    },
    loaderPageContent: {
      loading: "CARGANDO",
      loadMsg1: "Find our lowest price to destinations worldwide guranteed.",
      loadMsg2: "Search select and save. the fastest way to book your trip.",
      loadMsg3: "Get award winning support and special deals.",
    },
    geoipContent: {
      text1: "You have reached to the",
      text2: "Site of",
      text3: "Continue to the",
      text4: "Site at",
    },
    searchPageContent: {
      filterContent: {
        filterSearch: "FILTER YOUR SEARCH",
        filterBy: "Filter By",
        stops: "Stops",
        fareType: "Fare Type",
        priceRange: "Price Range",
        avgperPax: "Avg.per pax",
        airlines: "Airlines",
        selectAll: "Select All",
        clearAll: "Clear All",
        more: "More",
        showless: "Show less",
        onwardjourney: "Onward Journey",
        returnjourney: "Return Journey",
        depFrom: "Departure From",
        arrivalAt: "Arrival at",
        departure: "Departure",
        arrival: "Arrival",
        resetFilter: "Reset All",
        recommended: "Recommended",
        best: "Best",
        cheapest: "Cheapest",
        shortest: "Shortest",
        after: "After",
        before: "Before",
        airports: "Airports",
        flight: "Flight",
        flightDuration: "Flight Duration",
        outBound: "OutBound",
        inBound: "inBound",
        filterText:"We can't find flights for the selected filters. Please change your filter options."
      },
      itinContent: {
        depature: "Depature",
        return: "Return",
        nonRefund: "Non Refundable",
        refund: "Refundable",
        direct: "Direct",
        stop: "Stop",
        stops: "Stops",
        nonStop: "Non Stop",
        flightDetails: "Flight Details",
        select: "Select",
        modify: "Modify",
      },
      flightDetails: {
        cabin: "Cabin",
        overView: "OVERVIEW",
        baggage: "BAGGAGE",
        fareDetils: "FARE DETAILS",
        depature: "Depature",
        arrival: "Arrival",
        flight: "Flight",
        layoverTime: "LayoverTime",
        layover: "Layover",
        bookFlight: "Book This Flight",
        totaltripDuration: "Total Trip Duration",
        checkedBag: "Checked Baggage",
        carryOnBag: "CarryOn Baggage",
        bagContent1: "Up to 62 linear inches/158 linear centimeters",
        bagContent2: "Up to 50 pounds/23 kilograms",
        piece: "Piece",
        pieces: "Pieces",
      },
    },
    advancedOptions:{
      heading: "Advanced Options",
      airline: "Airline",
      airport: "Airport",
      include: "Include",
      exclude: "Exclude",
      Stops: "Stops",
      layoverTime: "Layover Duration",
      avoidCountry: "Avoid Country",
      airlineLabel: "Airline code/Name",
      anyStop: "Any Stop",
      nonStop: "Non Stop",
      upto1Stop: "Upto 1 Stop",
      upto2Stop: "Upto 2 Stops",
      upto3Stop: "Upto 3 Stops",
      fourStops: "4+ Stops",
      nopreference: "No Preference",
      hoursData1: "1-3 hours",
      hoursData2: "3-6 hours",
      hoursData3: "6-12 hours",
      hoursData4: "12+ hours",
      viaAirport: "Via-Airport",
      airportLabel: "Airport code/Name",
      directFlight: "Direct Flight",
      BaggageInclusive: "Baggage Inclusive Only",
      nearbyAirport: "Nearby Airport",
      resetBtn: "Reset",
      doneBtn: "Done"
    },
    fareDetContent: {
      fareDetails: "Fare Details",
      paymentDetails: "Payment Details",
      passenger: "Passenger",
      passengers: "Passengers",
      adult: "Adult",
      child: "Child",
      infant: "Infant",
      checkIn: "CHECK-IN",
      totalPrice: "Total Price",
      grandTotal: "Grand Total",
      fareSummary: "Fare Summary",
      pax:"Pax",
      base:"Base",
      tax:"Tax",
      basePrice: "Base Price",
      taxesFees: "Taxes & Fees",
      ticperPerson: "Ticket Price per person",
      priceInfo1: "All fares are quoted in",
      priceInfo2:"Some airlines may charge baggage fees. Your credit/debit card may be billed in multiple charges totaling the final total price.",
      confirmbtnText:"By clicking 'Confirm & Book', I agree that I have read and accepted the.",
      termsofUse: "Terms of use",
      perPerson: "Per Person",
    },
    baggageDetContent: {
      contents: {
        baggAndRules: "Baggage and Cancellation Rules",
        baggage: "Baggage Rules",
        cancelRules: "Cancellation Rules",
        checkIn: "Check-In",
        cabin: "Cabin",
        carryOn: "Carry-On",
        checked: "Checked Bag",
        piece: "Piece",
        pieces: "Pieces",
        baggageHeading:"Baggage",
        CancelRulesContent:"Please check with Airline websites"
    },
  },
    flightDetContent: {
      contents: {
        reviewBooking:"Review your booking",
        flightSelected: "Flight Selected",
        review:"Review",
        traveller:"Traveller",
        addons:"Addons",
        makePayment:"Make Payment",
        flightDetails: "Flight Details",
        backSearch: "Back To Search",
        depart: "DEPART",
        return: "RETURN",
        changePlanes: "Change planes at",
        connectTime: "Connecting Time",
        connectFlight: "Connecting flight may depart from a different terminal",
        continue: "Continue",
      },
    },
    flightInfoContent: {
      headings: {
        heading1: "Itinerary Information",
        heading2: "Traveller Details",
        heading3: "Booking Details",
        heading4: "Travel Details",
        heading5: "Flight Details",
        subHead1: "Passenger Contact Data",
      },
      contents: {
        travellerDetails: "Traveller Details",
        adult: "Adult",
        child: "Child",
        infants: "Infants",
        contactInfo: "Contact information",
        contactWhats: "Contact me on whatsapp",
        sendCoupon: "Send me the latest travel deals, special offers, coupons.",
        tripDuration: "Trip Duration",
        stops: "Stops",
        stop: "Stop",
        nonStop: "Non-Stop",
        add:"Add",
        departure:"Departure",
        return: "Return",
        errText:"Please add the required number of",
        contactText:"Note: All communication related to booking will be sent to this email address and mobile.",
        layover:"Layover Time",
        mustContinue: "You must agree to continue!",
        acknowledgement: "Acknowledgement",
        ackText: "I understand and agree with the",
        privacyPolicy: "Privacy Policy",
        termsofUse: "Terms of use, Fare rules",
        termsCondition: "Terms & Conditions",
        emailText:"Your Booking Details will be sent to this email address and mobile number.",
        infoText:"By selecting to continue I acknowledge that I have read and accept the Rules & Restrictions, Terms of use, Fare rules and Privacy Policy. In addition, I also confirm that I have verified that all information entered during the reservation process is accurate. I also acknowledge that this will serve as my electronic signature.",
        continue: "Continue",
      },
      labels: {
        firstName: "First Name",
        middleName: "Middle Name",
        lastName: "Last Name",
        salutation: "Salutation",
        gender: "Gender",
        male: "Male",
        female: "Female",
        dateofBirth: "Date of Birth",
        year: "Year",
        month: "Month",
        date: "Date",
        countryCode: "Country Code",
        phoneNumber: "Phone Number",
        emailId: "Email ID",
        mr: "Mr",
        miss: "Miss",
        mrs: "Mrs",
        mstr: "Mstr",
      },
    },
    paymentContent: {
      headingContent: {
        tripDetails: "Trip Details",
        paymentDetails: "Payment Details",
        billingHeading: "Billing Details",
        cardText: "We accept all major Credit Card",
        paymentHeading: "Choose Payment Mode",
        paymentSub: "Your Payments are secured by SSL certificate",
      },
      labelsContent: {
        duration:"Duration",
        fullName: "Full Name",
        country: "Country",
        stateLabel: "Region/State/Province",
        region: "Region",
        state: "State",
        Province: "Province",
        city: "City",
        travellers:"Travellers",
        contactDetails:"Contact Details",
        addressLine: "Address Line",
        postalCode: "Postal Code",
        countryCode: "Country Code",
        phoneNumber: "Phone Number",
        email: "Email",
        altContact: "Alternate Contact",
        phone: "Phone",
        cardNumber: "Card Number",
        NameonCard: "Name on Card",
        expiryDate: "Expiry Date",
        expiry: "Expiry(MM/YY)",
        cvvCode: "CVV Code",
        cvv: "CVV",
        cvvInfo: "3 digits printed on the back of the card",
        continueTobook: "Continue To Book",
      },
      fareUpgrade: {
        fareUpgradeHeading: "Upgrade Your Ticket",
        continueBasic: "Continue With Basic",
        perPassenger: "Per Pessenger(each-way)",
        upgradeNow: "Upgrade Now",
        congratsText: "Congratulations!",
        fareContent1: "You have saved upto 35% on baggage charges.",
        fareContent2:"Fare upgrade option selection for the following passengers.",
        fareHead: "Fare Upgrade",
        totalAmount: "Total Amount",
      },
      paymentMode: {
        heading: "Pay with Credit Card",
        subHead: "Credit Card",
        payNow: "Pay Now",
        payNowInfo:"On Clicking 'Pay Now' your card will be used for confirming the booking",
      },
    },
    bookingDetContent: {
      traveller: "Traveller",
      contactDetails: "Contact Details",
    },
    bookingviewContent: {
      statusText: "Your Booking Has Been",
      subText1:"Thank you for choosing our service! Your booking has been received and the reservation is processing.",
      subText2: "Soon your tickets will be issued and sent to your email.",
      status1: "Requested",
      status2: "Duplicate",
      status3: "Booked",
      status4: "Ticketed",
      status5: "Hold",
      status6: "Cancelled",
      headings: {
        bookingID: "Booking ID",
        bookedOn: "Booked on",
        bookingStatus: "Booking Status",
        depature: "Depature",
        return: "Return",
        arrival: "Arrival",
        flightdetails: "Flight details",
        tripID: "Trip ID",
        bookingDate: "Booking Date",
        bookingDetails: "Booking Details",
        eticNumber: "E-ticket number:",
        manageTrip: "Manage Trip",
        travellerDetails: "Traveller Details",
        contactDetails: "Contact Details",
        travelDetails: "Travel Details",
        flightTime: "Flight Time",
        flightNumber: "Flight number",
        stops: "Stops",
        flight: "Flight",
        class: "Class",
        pnr:"Pnr",
        layover:"Layover Time",
        passengerDetails: "Passenger Details",
        subtext1: "The e-ticket number is valid for all flights",
        termsCondition: "TERMS & CONDITIONS",
        viewTicket: "View Ticket",
        roundTrip: "ROUND TRIP",
        onewayTrip: "ONEWAY TRIP",
        multiTrip: "MULTICITY",
      },
      modifyBooking:{
        heading: "Modify your booking",
        bookingRef: "Booking Reference",
        outbound: "Outbound Between",
        inbound: "Inbound Between",
        notes: "Notes",
        requestText: "Please mention your request below.Our support team will be in touch with you at the soonest posible",
        modifyText1: "Your modification request has been recorded.",
        modifyText2: "Our customer support executive will get in touch with you soon.",
      },
      buttonsText: {
        cancelBook: "Cancel Booking",
        modifyBook: "Modify Booking",
        print: "Print",
        startLive: "Start live chat",
        bookanotherFlight: "Book Another Flight",
        modify: "Modify",
        Cancel: "Cancel",
        request: "Request",
      },
      manageTripContent: {
        heading: "For more information, contact us",
        text1: "How much it costs",
        text2: "How to change a ticket",
        recommended: "Recommended",
        reachoutEmail: "Reach out to us by email at",
        chat: "Chat",
        mustAgree: "You must agree to continue!",
        checkBox:"By using this chat I allow my data to be stored for security and quality review as mentioned in the Privacy Policy.",
        emailInfo:"We will get back to you as soon as possible.Feel free to contact us for any Queries or Support.",
        chatNotes:"Please note that a support ticket will only be created for inquiries submitted through channels listed on this page. We will not respond to messages received outside of these options, including but not limited to those sent via regular mail and sent directly to employees.",
        content1:"If changes are allowed by the airline, the following charges apply",
        content2: "Airline's penalty when included in the ticket's fare rules",
        content3:"Our service fee of 56 CAD per person (not applicable for Platinum Support Package)",
        content4: "Any difference in fare and taxes from the original trip",
      },
    },
    loginContents: {
      signIn: "Sign in",
      signUp: "Sign Up",
      subHaed: "Sign in and Get lots of Cashback Rewards and Discount",
      labels: {
        Email: "",
        emailAddress: "Email Address",
        password: "Password",
        rememberMe: "Remember Me",
        createAnaccount: "Create an Account",
        resetPassword: "Reset your Password",
        rememberPass: "Remember your password?",
        emailnotfound: "Email Not Found",
        emailSentMsg:"You will receive a link to reset the password, if your email is already registered. Please follow the instructions in the email to reset your password.",
      },
      buttonsLink: {
        login: "Login",
        or: "OR",
        createAccount: "Create New Account",
        forgotPassword: "Forgot Password",
        submit: "Submit",
      },
      successNotify: {
        text1: "Login Successfully",
      },
      errorNotify: {
        text1: "User Not Found",
        text2: "Please Enter Correct Details",
        errorMsg: "Incorrect Email or Password.",
      },
      validError: {
        email: "Please enter your email",
        email1: "E-mail must be valid",
        password: "Please enter your Password",
      },
      placeholder: {
        email: "Enter your Email",
        password: "Enter your Password",
      },
    },
    registerContents: {
      signIn: "Sign in",
      signUp: "Sign Up",
      create: "Create",
      account: "Account",
      subHaed: "For security, please sign in to access your information",
      labels: {
        register:"REGISTER",
        name: "Name",
        fName: "First Name",
        mName: "Middle Name",
        lName: "Last Name",
        emailId: "Email ID",
        password: "Password",
        phoneNumber: "Phone Number",
        confirmPassword: "Confirm Password",
        rePassword: "Re-Password",
        byClick: "By clicking on register, I Understand & agree to world travel",
        YouHaveaccount: "You have already an account?",
      },
      buttonsLink: {
        register: "Register",
        login: "Login",
        submit: "Submit",
        alreadyAccount: "You have already an account?",
        signIn: "Sign In",
        tryAgain: "Try Again"
      },
      successNotify: {
        text1: "Login Successfully",
        text2: "Register Successfully",
      },
      errorNotify: {
        text1: "User Not Found",
        text2: "Please Enter Correct Details",
      },
      validError: {
        userName: "Please enter your username",
        email: "Please enter your email",
        email1: "E-mail must be valid",
        email2: "E-mail is required",
        password: "Please enter your Password",
        passNotmatch: "Passwords do not match",
      },
      placeholder: {
        name: "Enter your Name",
        email: "johndoe@gmail.com",
        password: "Enter your password",
        rePassword: "Enter your Re-password",
      },
    },
    newPasswordContents:{
      labels:{
        createPassword:"Create Your Password",
        newPassword:"New Password",
        confirmPassword:"Confirm Password",
        resetBtn:"Reset"
      }
    },
    errorContent:{
      dialog2Content:{
        text1:"Your modification request has been recorded.",
        text2:"Our customer support executive will get in touch with you soon.",
        text3:"Your request has been received. Our customer support team will get back to you at the soonest possible."
      },
      dialog3Content:{
        text1:"OOPS!",
        text2:"Your request cannot be processed at this moment.",
        text3:"Please Search Another Flight.",
      },
      dialog4Content:{
        text1:"OOPS!",
        text2:"Something went wrong.",
        text3:"Please contact customer support.",
      },
      bookingViewDailog3:{
        text1:"Invalid PNR",
        text2:"please try again",
      },
      buttonText:{
        tryAgain:"Try Again",
        returnHome:"Return to Home",
        okbtn:"OK"
      }
    },
    loaderContent: {
      confirmText: "Please wait while we confirm your booking",
      retrieveText: "Please wait while we retrieve your booking",
    },
    viewBookingContent: {
      heading: "VIEW YOUR BOOKING",
      labels: {
        bookId: "Booking ID",
        lastName: "Last Name",
      },
      buttons: {
        submit: "Submit",
        or: "OR",
        returnHome: "Return to Home",
        print: "Print",
      },
    },
    myBooking: {
      header: {
        heading1: "UPCOMMING TRIP",
        heading2: "COMPLETED TRIP",
        heading3: "CANCELLED TRIP",
        heading4: "FAILED TRIP"
      },
      lebels: {
        bookingId: "Booking ID",
        travelDate: "Travel Date",
        bookingDate: "Booking Date",
        personalProfile: "Personal Profile",
        profile: "Profile",
        myBooking: "My Bookings",
        status: "Status"
      },
      contents: {
        emptyText1: "Looks empty, you've no upcoming bookings.",
        emptyText2: "When you book a trip, you will see your itinerary here.",
      },
      buttons: {
        myBookings: "My Bookings",
        viewBooking: "VIEW BOOKING",
        logOut: "LOG OUT",
        bookNow: "Book Now",
      },
    },
    myProfile: {
      headings: {
        profile: "Profile",
        loginDetails: "Login Details",
        personalProfile: "Personal Profile",
        myBookings: "My Bookings",
        subHead1: "Basic info, for a faster booking experience",
        subHead2: "Manage your email address mobile number and password",
      },
      contents: {
        ok: "OK",
        successText: "Updated Successfully...",
      },
      labels: {
        firstName: "FIRST NAME",
        middleName: "MIDDLE NAME",
        lastName: "LAST NAME",
        birthDay: "BIRTHDAY",
        gender: "GENDER",
        male: "MALE",
        female: "FEMALE",
        others: "OTHERS",
        userId: "USER ID",
        emailId: "EMAIL ID",
        selectDate: "Select Date",
        unverified: "Unverified"
      },
      validError: {
        firstName: "Please enter First Name",
        lastName: "Please enter Last Name",
      },
      buttons: {
        update: "UPDATE",
        edit: "EDIT",
        verify: "Verify"
      },
    },
    contactContent: {
      heading1: "We are happy to deal with all enquiries and requests",
      subHead1:"Let Us know What You need, and we'll respond as soon as possible",
      formLabels: {
        name: "Name",
        natureEnquiry: "Nature of Enquiry",
        phoneNumber: "Phone Number",
        email: "Email",
        bookingRef: "Booking Reference /PNR No",
        comment: "Comments",
        questions: "Questions",
        enterCaptcha: "Enter Captcha",
        submit: "Submit",
      },
      natureOfEn: {
        generalEnq: "General Enquiry",
        booking: "Bookings",
        cancelGroups: "Cancellations Groups",
        packagesHotel: "Packages and HotelsComplaints",
        reportIss: "Report Issue",
      },
    },
    footerContents: {
      want: "Want",
      toTake: "to Take",
      tourPackages: "Tour Packages",
      quicklink: "Quick Link",
      company: "Company",
      support: "Support",
      joinOur: "Join Our",
      newsletter: "Newsletter & Update",
      package: "Package",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      termsofUse: "Terms of use",
      email: "Email here...",
      phoneNumber: "Phone Number...",
      privacyPolicy: "Privacy policy",
      myBookings: "My Bookings",
      followUs: "FOLLOW US",
      customerNumber: "Customer Service Number",
      copyright: "Copyright",
      allrights: "All rights reserved",
    },
    pageErrorContents:{
      errorText1:"No flights offered by airlines included on our website matches you search criteria",
      errorText2:"Please check flights from other airports or change your search request",
      changeSearchBtn:"Change your search"
    },
    pageNotContents:{
      errorText1:"Error 404",
      errorText2:"Sorry, the destination you are looking for is not found.",
      backHomeBtn:"Back to Home"
    }
  }