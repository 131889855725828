<template>
 <v-app>
    <v-main>
      <div v-if="domaintrue" class="text-white f-size-14 d-flex justify-content-between align-center px-5 py-2" style="background-color: #005672;">
        <div>
          <span>You have reached {{ portalsiteTitle }} {{ countryname }} website.</span>
          <span class="text-capitalize ms-1">{{ portalsiteTitle }}</span>.
          <span>Click here to visit</span>
          <span @click="routerDomain" class="border-bottom border-1 fw-500 ms-1" style="cursor: pointer">{{ userDomain }}</span>
        </div>
        <div>
          <v-icon @click="domaintrue = false" color="red" class="bg-white rounded-circle">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>

      <div v-if="datashow">
        <div v-if="whatsappApi">
          <a :href="whatsappApi" class="whatsapp-float" target="_blank" rel="noopener noreferrer">
            <v-icon class="whatsappImg" size="40">mdi-whatsapp</v-icon>
          </a>
        </div>
        <div id="navbar">
          <headerCom1 @localChanged="updateLocale" />
        </div>
        <div class="bg-pages">
          <router-view />
        </div>

        <div id="food">
          <footerCom />
        </div>
      </div>
    </v-main>
    <v-dialog v-model="portalError" persistent>
        <div class="d-flex justify-center">
          <v-card class="p-4">
            <h3 class="text-center">Oops! Site is being updated.</h3>
            <h5 class="text-center">Sorry for the inconvenience. We will be right back with exciting new features.</h5>
            <h6 class="text-center">Please visit again after some time.</h6>
            <div class="d-flex justify-space-evenly mt-3">
              <v-btn @click="reloadPage()" color="#63AB45">Retry</v-btn>
            </div>
          </v-card>
        </div>
      </v-dialog>
  </v-app>
</template>

<script>
import headerCom1 from "@/components/headerComponent.vue";
import footerCom from "@/components/FooterComponent.vue";
import CryptoJS from "crypto-js";
import axios from "axios";
import moment from "moment";
import { setConfigDataToLocalStorage, getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  name: 'App',
  components: {
    headerCom1,
    footerCom,
  },
  data(){
    return{
      navbar: true,
      food: true,

      loading: false,
      datashow:false,
      portalError:false,
      i18nlocaleLang: "",
      countryOptions: [],
      siteContentData: "",
      languageData: "",
      getLanguages: "",
      countryIsocode: "",

      whatsappApi: "",
      tawktoApi:"",

      SearchDomain: "",
      portalsiteTitle: "",
      domaintrue: false,
      userDomain:"",
      envPortalCalled: false,

      configReq: {
        service_type: "GetPortalConfigRQ",
        api_ver: "1",
        payload: {
          domain_name: "",
          env: "CERT",
          language: "EN",
          token: "jsdjOJDKJ7675",
        },
      },

      configUser_Id: "",
      getEndpoint:"",

      loaderReq:{
          "payload": {
            "domain": "uatus.zetsfly.com",
            "etoken": ""
          }
        }
      }
      
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        location.reload();
      }, 300);
      window.location.href = '/';
      this.portalError = false;
    },

    routerDomain(){
      localStorage.removeItem('prefDomain');
      window.location.href = `https://${this.userDomain}?ref=web&redirect=false`;
    },

    async getloaderPortal(){
      let hostname = window.location.hostname;

      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey = Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey

      console.log(this.loaderReq,'loderred...2');

      await axios.post("https://otaget.nexus2.wistirna.com",this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.response_type === "success"){
            this.getEndpoint = response.data.endpoint;
            this.portalError = false;
            this.datashow = true;

            if(this.getEndpoint && !this.envPortalCalled){
              this.envPortal();
              this.envPortalCalled = true;
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
          setTimeout(()=>{
            this.portalError = true;
          },3000)
        });
    },


    envPortal() {
      this.configReq.payload.domain_name = this.loaderReq.payload.domain;

      console.log(this.configReq, "configReqconfigReqconfigReq");
      axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            this.loading = true;
            this.portalError = false;
            let configResponse = response.data;

            setConfigDataToLocalStorage(configResponse);
            const getConfigResponse = getConfigDataFromLocalStorage();

            if(getConfigResponse){
              console.log(configResponse, "configResponseconfigResponse");

              this.SearchDomain = this.configReq.payload.domain_name;
              this.portalsiteTitle = configResponse.payload.portal_configuration.site_title;
              this.countryname = configResponse.payload.portal_configuration.country.name;
              this.countryIsocode = configResponse.payload.portal_configuration.country.iso_code;
              this.countryOptions = configResponse.payload.portal_configuration.country_options;
              
              // Geolocation Redirection
              if(this.countryIsocode && this.countryOptions){
                // this.geolocationRedirection();
              }
              // Geolocation Redirection End
              
              this.getLanguages = configResponse.payload.portal_configuration.languages;
              this.languageData = configResponse.payload.portal_configuration.language_data;
              if (this.languageData) {
                this.getlangaugeUrl();
                // this.localeLanguage();
              } else {
                this.$i18n.locale = "CA-en";
              }

              if (this.getLanguages && this.countryIsocode) {
                this.setLanguage();
                console.log("working...1");
                // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
                // console.log(this.$i18n.locale, "langresponseresponse...1");
              } else {
                this.$i18n.locale = "CA-en";
                console.log("working...2");
                // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
              }

              this.indexHeader();
          }
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // alert("Something went difficulties...");
          this.loading = false;
          this.datashow = false;
          setTimeout(()=>{
            this.portalError = true;
          },3000)
        });
    },


    async geolocationRedirection(){
      const configResponse = await getConfigDataFromLocalStorage();
      // Geolocation Redirection

      if(configResponse){
        let geoIsocode = null;
        let geoCountrydomain = null;
        
        if(configResponse.payload.portal_configuration.geoip_location){
          geoIsocode = configResponse.payload.portal_configuration.geoip_location.country_iso_code;
          geoCountrydomain = configResponse.payload.portal_configuration.geoip_location.country_domain;
        }

        let findDomainIso = null;
        let findIsocode = null;
        if(this.countryOptions && geoIsocode){
          findDomainIso = this.countryOptions && geoIsocode ?  (this.countryOptions.find((v) => v.country_code == geoIsocode)) || null : null;
          findIsocode = this.countryIsocode && geoIsocode ? (this.countryIsocode == geoIsocode) || null : null;
        }

        let getredirect = null;
        if (this.$route.query && this.$route.query.redirect) {
          try {
            getredirect = JSON.parse(this.$route.query.redirect);
          } catch (e) {
            console.log("Invalid redirect value:", this.$route.query.redirect);
            getredirect = null;
          }
        }
        // const getrouteRef = this.$route.query && Object.keys(this.$route.query).length > 0 ? (this.$route.query.ref || null) : null; 
        const getusercountry = this.countryIsocode ? this.countryIsocode : null;
             
        const getrouteiso = this.$route.query ? (this.$route.query.usercountry || null ) : null;
        let getrouterquery = this.$route.query && Object.keys(this.$route.query).length > 0 ? this.$route.query : null;
        
        const validKeys = ["ref", "redirect", "usercountry"];
        const excludedKeys = ["ref", "redirect"];

        const hasOnlyValidKeys = getrouterquery && Object.keys(getrouterquery).every((key) => validKeys.includes(key));
        const hasOnlyExcludedKeys = getrouterquery && Object.keys(getrouterquery).every((key) => excludedKeys.includes(key));
        const isRefOnlyWeb = getrouterquery && Object.keys(getrouterquery).length === 1 && getrouterquery.ref === "web";
        const isRefWebwithRedirect = getrouterquery && Object.keys(getrouterquery).length === 2 && getrouterquery.ref === "web" && getredirect;
        
        const isUnwantedQuery = !getrouterquery || isRefOnlyWeb || (!isRefOnlyWeb && isRefWebwithRedirect) 
         || !hasOnlyValidKeys || (hasOnlyValidKeys && !hasOnlyExcludedKeys) ||(!hasOnlyValidKeys && hasOnlyExcludedKeys);
        
        // Encrypt User Country 
        let secretqueryKey = "secretquery";
        let encryptedquery = null;   
        
        if(isUnwantedQuery){
          if(findDomainIso && !findIsocode){
            if(isUnwantedQuery){
              encryptedquery = CryptoJS.AES.encrypt(JSON.stringify(getusercountry),secretqueryKey).toString();
              const encodequery = encryptedquery ? btoa(encryptedquery) : null;
              let encryptiso = encodequery ? encodequery : null;
              window.location.href = `${geoCountrydomain}?ref=web&redirect=true&usercountry=${encryptiso}`;
            }
          } 
        }
        else{
          this.domaintrue = false;
        }

        // Decrypt User Country 
        
        let finduserIso = null;
        if(getrouteiso){      
          const decodedEncrypted = getrouteiso ? atob(getrouteiso) : null;
          const decrypted = CryptoJS.AES.decrypt(decodedEncrypted, secretqueryKey).toString(CryptoJS.enc.Utf8); 
          
          // Encrypt & Decrypted User Country End
          let usercountryIso = decrypted ? JSON.parse(decrypted) : null;  
          finduserIso = this.countryOptions && usercountryIso ?  (this.countryOptions.find((s) => s.country_code == usercountryIso)) || null : null;

          if(getrouteiso && getredirect && finduserIso){
            this.domaintrue = true;
            let getuserDomain = finduserIso.portal_url ? finduserIso.portal_url : null; 
  
            let trimedDomain = getuserDomain.replace("https://","");
            this.userDomain = trimedDomain.charAt(0).toUpperCase() + trimedDomain.slice(1); 
          }
        }
        else{
          this.domaintrue = false;
        }
      }
      // Geolocation Redirection End
    },


    

    async indexHeader() {
      const getConfigData = await getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;
        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        let plugins = "";
        plugins = getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')

        if (plugins.includes("whatsapp")) {
          this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.whatsappApi = "";
        }
        // console.log(this.whatsappApi,'whatsappAPiiii')

        if (plugins.includes("tawkto")) {
          this.tawktoApi = getConfigData.payload.portal_configuration.API_endpoints.tawkto;
        } else {
          this.tawktoApi = "";
        }

        // TawkTo code
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.tawktoApi;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      }
    },

    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log("App locale updated to:", this.i18nlocaleLang);
    },
       setLanguage() {
      let getLang = this.getLanguages[0].code;
      console.log(getLang,this.countryIsocode,'countryIsocode')
      let savedLanguage = `${this.countryIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },
    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";

      if (storedLocale) {
        getlocalIso = storedLocale.split("-")[0];
        getlocalLang = storedLocale.split("-")[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split("-")[0];
        getsetlang = storedSetLang.split("-")[1];
      }

      if (storedSetLang && storedLocale) {
        console.log("Check...1");
        if (storedLocale == storedSetLang) {
          console.log("Check...2");

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        } else if (storedLocale !== storedSetLang) {
          console.log(
            getlocalIso,
            getsetIso,
            "Check...3",
            getlocalLang,
            getsetlang
          );
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {
            localStorage.removeItem("defaultlang");
            console.log("Check...4");
            return storedSetLang;
            // this.i18n.locale = storedSetLang;
          } else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, "Check...5");

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      } else if (storedSetLang) {
        console.log("Check...6");

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      } else if (storedLocale) {
        console.log("Check...7");

        return storedLocale;
        // this.i18n.locale = storedLocale;
      } else {
        console.log("Check...8");

        return "CA-en";
        // return `${this.countryIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`;
      }
    },

    getlangaugeUrl() {
      let langData = this.languageData;
      console.log(langData, 'languageDatalanguageDatalanguageData...1');
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      // console.log(localLang, 'languageDatalanguageDatalanguageData...2');

      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      Object.keys(langData).forEach((v) => {
        console.log(
          v,
          this.countryIsocode,
          "languageDatalanguageDatalanguageData...3"
        );
        if (v == this.countryIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach((local) => {
            // console.log(local, 'dasasasasdas')
            if (local == localLang) {
              langdataUrl = lang[local];
              console.log(local, localLang, langdataUrl, "dasasasasdas...2");

              axios
                .get(langdataUrl, {
                  headers: {
                    "Content-Type": "text/html; charset=utf-8",
                  },
                })
                .then((response) => {
                  if (response) {
                    // this.aboutContent = response.data
                    // console.log(response, "langresponseresponse...2");
                    // console.log(response.data, "langresponseresponse");
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);
                    // setInterval(() => {
                    //   location.reload();
                    // }, 500);
                    // this.dataloader = false;
                  }
                  // else {
                  //   this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
                  //   console.log(this.$i18n.locale, "langresponseresponse...1");
                  // }
                })
                .catch((error) => {
                  console.log(error, "errorrLangaugeAPI.....");

                  this.$i18n.locale = "CA-en";
                  // this.dataloader = true;
                });
            }
          });
        }
      });
    },

    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, "")
          .replace(/;\s*$/, ""); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error("Error processing language content:", error);
        return {};
      }
    },

    setLanguageMessages(locale, messages) {
      this.$i18n.setLocaleMessage(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale, "setLanguageMessagessetLanguageMessages");
    },

  },

  mounted(){
    this.getloaderPortal(); 
  },
  watch: {
    i18nlocaleLang: {
      immediate: true,
      handler: function () {
        // this.localeLanguage();
        this.getlangaugeUrl();
      },
      // deep: true,
    },
    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();
        // this.domaintrue = newValue === "/" ? true : false;
        console.log(this.$route,'sdasdasdasd')
        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },
};

</script>

<style scoped>
 @import "@/assets/style.css";

</style>
