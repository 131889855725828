<template>
  <div class="pagewrap modify-search" v-if="pageloader">
    <v-form ref="form" @submit.prevent="submitform">
      <div class="head-wrapper" tabindex="1" ref="topElement">
        <!-- review booking flight-->
        <div class="flight-search">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <span class="fw-bold theme-text-white"
                  >{{ $t("flightDetContent.contents.reviewBooking")}}</span
                >
              </div>
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                <ul class="reviewStatus step2">
                  <li>
                    <span class="numbering completed">1</span
                    ><span class="reviewText grayText font12"
                      >{{ $t("flightDetContent.contents.flightSelected")}}</span
                    >
                  </li>
                  <li>
                    <span class="numbering onpage">2</span
                    ><span class="reviewText grayText font12 active"
                      >{{ $t("flightDetContent.contents.review")}}</span
                    >
                  </li>
                  <li>
                    <span class="numbering">3</span
                    ><span class="reviewText grayText font12"
                      >{{ $t("flightDetContent.contents.traveller")}} &amp; {{ this.$t("flightDetContent.contents.addons")}}</span
                    >
                  </li>
                  <li>
                    <span class="numbering">4</span
                    ><span class="reviewText grayText font12"
                      >{{ $t("flightDetContent.contents.makePayment")}}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- body section -->
      <div class="content-section">
        <!-- flight revew booking page -->
        <div class="review-flight py-3" style="background-color: #efeeee">
          <div class="container">
            <div class="row">
              <div
                class="col-12 col-md-9"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div
                  class="d-flex justify-content-between align-items-start mb-3"
                >
                  <div class="fw-bold">{{ $t("flightDetContent.contents.flightDetails")}}</div>
                  <div>
                    <span class="font-small"
                      ><i class="bi bi-arrow-left mx-2"></i>{{ $t("flightDetContent.contents.backSearch")}}</span
                    >
                  </div>
                </div>
                <!-- flight details section -->
                <div v-for="(data, index) of roundresult" :key="index">
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                  > {{ console.log(data, "datass") }}
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-12">
                        <span class="">
                          <span class="fw-bold text-uppercase">{{ $t("flightDetContent.contents.depart")}} : </span>
                        
                          <span style="font-weight: 500">
                            {{ getdepName1(data.fromTrip) }} -
                            {{ getarrName1(data.toTrip) }}</span
                          >
                        </span>
                      </div>
                      <div class="col-4 d-none text-end">
                        <span class="font-small">Fare Rules</span>
                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-for="(data1, index) of data.Depature"
                      :key="index"
                    > 
                      <div class="col-sm-12">
                        <div class="px-3">
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >
                                {{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">
                                  {{ getdated(data1.Departure.Date) }}</span
                                >
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                                <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{ data.Depaturejourney.Stops }} Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:
                                  {{ data1.Equipment.AircraftCode }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span><br>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                <span v-if="data.depBaggage">
                                  {{ data.depBaggage.PieceAllowance.TotalQuantity || 0}}
                                  {{ data.depBaggage.PieceAllowance .TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                </span>
                                <span v-if="data.BaggageData">
                                  {{ data.BaggageData.PieceAllowance.TotalQuantity || 0}}
                                  {{ data.BaggageData.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                </span> </span>
                                <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} :
                                <span v-if="data.depBaggage">
                                  {{ data.depBaggage.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                </span>
                                <span v-if="data.BaggageData">
                                  {{ data.BaggageData.PieceAllowance.TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                </span> </span>
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div 
                                                        class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Depature.length - 1 && data.Return">
                                                        Layover Time: {{ data.layoverTimes1[index] }}</div>
                                                    <div 
                                                        class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Depature.length - 1 && !data.Return">
                                                        Layover Time: {{ data.layoverTimes[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Depature.length - 1 && data.Return">
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes1[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Depature.length - 1 && !data.Return "
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- flight details section -->
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                    v-if="data.Return"
                  >
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-12">
                        <span class="fw-bold">
                          <span class="text-uppercase">{{ $t("flightDetContent.contents.return")}} : </span>
                          <span style="font-weight: 500"
                            >{{ getdepName1(data.toTrip) }} -
                            {{ getarrName1(data.fromTrip) }}</span
                          >
                        </span>
                      </div>
                      <!-- <div class="col-4 text-end">
                                        <span class="font-small">Fare Rules</span>
                                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                                    </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="px-3"
                          v-for="(data1, index) of data.Return"
                          :key="index"
                        >
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium fw-500">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                                        class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >
                                {{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">{{
                                  getdated(data1.Departure.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                                <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{ data.Returnjourney.Stops }} Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:
                                  {{ data1.Equipment.AircraftCode }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase fw-bold"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                {{ data.arrBaggage.PieceAllowance.TotalQuantity || 0}}
                                {{ data.arrBaggage.PieceAllowance.TotalQuantity > 1 ?
                                    $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")
                                }}</span>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} : {{
                                  data.arrBaggage.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                }}</span>
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div
                                                        class="border d-inline-block theme-border-radius font-small p-2" v-if="index !== data.Return.length - 1">
                                                        Layover Time: {{ data.layoverTimes2[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Return.length - 1"
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes2[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(data, index) of multiresult" :key="index">
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                  >
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-12">
                        <span class="fw-bold">
                          <span class="text-uppercase"></span>

                          <span>
                            {{ getdepNameMulti(data.muticityData[0]) }} -
                            {{ getarrNameMulti(data.muticityData[0]) }}</span
                          >
                        </span>
                      </div>
                      <!-- <div class="col-4 text-end">
                                        <span class="font-small">Fare Rules</span>
                                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                                    </div> -->
                    </div>
                    <div
                      class="row"
                      v-for="(data1, index) of data.Flight1"
                      :key="index"
                    >
                      <div class="col-sm-12">
                        {{ console.log(data1, "datass") }}
                        <div class="px-3">
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium fw-500">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                                        class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >
                                {{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">
                                  {{ getdated(data1.Departure.Date) }}</span
                                >
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                                <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{
                                    data.Flight1JourneyTime.Stops
                                  }}
                                  Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:{{
                                    data1.Equipment.AircraftCode
                                  }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase fw-bold"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span><br>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                {{
                                  data.BaggageData1.PieceAllowance
                                    .TotalQuantity || 0
                                }}
                                {{
                                  data.BaggageData1.PieceAllowance
                                    .TotalQuantity > 1
                                    ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")
                                }}</span
                              ><span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} :
                                {{
                                  data.BaggageData1.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                }}</span
                              >
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div
                                                        class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Flight1.length - 1">
                                                        Layover Time: {{ data.layoverTimes1[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Flight1.length - 1"
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes1[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- flight details section -->
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                    v-if="data.Flight2"
                  >
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <span class="fw-bold">
                          <span class="text-uppercase"></span>
                          <span>
                            {{ getdepNameMulti(data.muticityData[1]) }} -
                            {{ getarrNameMulti(data.muticityData[1]) }}</span
                          >
                        </span>
                      </div>
                      <!-- <div class="col-4 text-end">
                                        <span class="font-small">Fare Rules</span>
                                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                                    </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="px-3"
                          v-for="(data1, index) of data.Flight2"
                          :key="index"
                        >
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium fw-500">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                                        class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >{{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">{{
                                  getdated(data1.Departure.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                               <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{
                                    data.Flight2JourneyTime.Stops
                                  }}
                                  Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:{{
                                    data1.Equipment.AircraftCode
                                  }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase fw-bold"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span><br>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                {{
                                  data.BaggageData2.PieceAllowance
                                    .TotalQuantity || 0
                                }}
                                {{
                                  data.BaggageData2.PieceAllowance
                                    .TotalQuantity > 1
                                    ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")
                                }}</span
                              ><span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} :
                                {{
                                  data.BaggageData2.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                }}</span
                              >
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div
                                                        class="border d-inline-block theme-border-radius font-small p-2" v-if="index !== data.Flight2.length - 1">
                                                        Layover Time: {{ data.layoverTimes2[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Flight2.length - 1"
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes2[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                    v-if="data.Flight3"
                  >
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <span class="fw-bold">
                          <span class="text-uppercase"></span>
                          <span>
                            {{ getdepNameMulti(data.muticityData[2]) }} -
                            {{ getarrNameMulti(data.muticityData[2]) }}</span
                          >
                        </span>
                      </div>
                      <!-- <div class="col-4 text-end">
                                        <span class="font-small">Fare Rules</span>
                                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                                    </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="px-3"
                          v-for="(data1, index) of data.Flight3"
                          :key="index"
                        >
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium fw-500">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                                        class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >{{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">{{
                                  getdated(data1.Departure.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                                <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{
                                    data.Flight3JourneyTime.Stops
                                  }}
                                  Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:{{
                                    data1.Equipment.AircraftCode
                                  }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase fw-bold"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span><br>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                {{
                                  data.BaggageData3.PieceAllowance
                                    .TotalQuantity || 0
                                }}
                                {{
                                  data.BaggageData3.PieceAllowance
                                    .TotalQuantity > 1
                                    ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")
                                }}</span
                              ><span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} :
                                {{
                                  data.BaggageData3.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                }}</span
                              >
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div
                                                        class="border d-inline-block theme-border-radius font-small p-2" v-if="index !== data.Flight3.length - 1">
                                                        Layover Time: {{ data.layoverTimes3[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Flight3.length - 1"
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes3[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="theme-box-shadow theme-border-radius bg-light mb-3"
                    v-if="data.Flight4"
                  >
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <span class="fw-bold">
                          <span class="text-uppercase"></span>
                          <span>
                            {{ getdepNameMulti(data.muticityData[3]) }} -
                            {{ getarrNameMulti(data.muticityData[3]) }}</span
                          >
                        </span>
                      </div>
                      <!-- <div class="col-4 text-end">
                                        <span class="font-small">Fare Rules</span>
                                        <v-icon class="bi bi-info-circle-fill ms-1"></v-icon>
                                    </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div
                          class="px-3"
                          v-for="(data1, index) of data.Flight4"
                          :key="index"
                        >
                          <ul class="row py-1 depature d-flex justify-content-between">
                            <li class="col-12 pb-3 pt-3">
                              <div class="float-start">
                                <v-img
                                  :src="data1.MarketingCarrier.logo"
                                  width="40px"
                                ></v-img>
                              </div>
                              <div class="float-start ms-2" style="width: 18%">
                                <div class="font-medium fw-500">
                                  {{ data1.MarketingCarrier.Name }}
                                </div>
                                <div class="airline">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </div>
                              </div>
                              <!-- <div
                                                        class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                        Class - SS</div> -->
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon style="color: gray !important" size="20"
                                  >mdi-seat-passenger</v-icon
                                >
                                {{ translateClass(data.cabinClass) }}
                              </div>
                              <div class="font-medium float-start" style="margin-left:2rem;margin-right:3rem;">
                                <v-icon
                                  style="color: gray !important"
                                  size="20"
                                  >{{
                                    data.Refund == "Refundable"
                                      ? "mdi-cash"
                                      : "mdi-cash-remove"
                                  }}</v-icon
                                >
                                <span class="ps-1">{{ translateRefund(data.Refund) }}</span>
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Departure.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightdep(data1) }} |
                                <span class="fw-normal">{{
                                  getdated(data1.Departure.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Departure.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 ps-0">
                              <div class="float-start">
                                <div class="float-start me-1">
                                  <v-icon class="bi bi-clock"></v-icon>
                                </div>
                                <span class="font-medium">{{
                                  data1.FlightDetail.FlightDuration.Value
                                }}</span>
                                <!-- <span class="font-small d-block"
                                  >{{
                                    data.Flight4JourneyTime.Stops
                                  }}
                                  Stops</span
                                >
                                <span class="airline d-block ms-4 ps-1"
                                  >Equipment:{{
                                    data1.Equipment.AircraftCode
                                  }}</span
                                > -->
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3">
                              <div class="font-medium fw-bold text-uppercase">
                                {{ data1.Arrival.AirportCode }}
                              </div>
                              <div class="font-medium fw-bold">
                                {{ getflightarr(data1) }} |
                                <span class="fw-normal">{{
                                  getdated1(data1.Arrival.Date)
                                }}</span>
                              </div>
                              <div class="font-small">
                                {{ data1.Arrival.AirportName }}
                              </div>
                            </li>
                            <li class="col-6 col-md-6 col-lg-3 pb-3 d-none">
                              <span
                                class="font-small text-uppercase fw-bold"
                                style="font-weight: 700 !important"
                                ><v-icon
                                  class="bi bi-briefcase me-1"
                                  font-size="16px"
                                ></v-icon>
                                {{ $t("baggageDetContent.contents.baggageHeading")}}
                              </span><br>
                              <span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.checkIn")}} :
                                {{
                                  data.BaggageData4.PieceAllowance
                                    .TotalQuantity || 0
                                }}
                                {{
                                  data.BaggageData4.PieceAllowance
                                    .TotalQuantity > 1
                                    ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")
                                }}</span
                              ><span class="font-small d-block"
                                >{{ $t("baggageDetContent.contents.cabin")}} :
                                {{
                                  data.BaggageData4.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece") }}
                                }}</span
                              >
                            </li>
                            <li class="col-12 text-center">
                              <!-- <div
                                                        class="border d-inline-block theme-border-radius font-small p-2" v-if="index !== data.Flight4.length - 1">
                                                        Layover Time: {{ data1.layoverTimes4[index] }}</div> -->
                              <div
                                class="border d-inline-block theme-border-radius font-small p-2"
                                v-if="index !== data.Flight4.length - 1"
                              >
                                {{ $t("flightDetContent.contents.changePlanes")}}
                                {{ data1.Arrival.AirportCode }}, {{ $t("flightDetContent.contents.connectTime")}} :
                                <span class="fw-bold">{{
                                  data.layoverTimes4[index]
                                }}</span>
                                {{ $t("flightDetContent.contents.connectFlight")}}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- travel insurance section -->
                <!-- <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">Add-ons</div>
                            </div>
                            <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <span class="fw-bold">Secure your trip</span>
                                        <p class="font-small mb-0">See all the benefits you get
                                            for
                                            just $ 159 (18% GST
                                            included)
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex flex-column">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" checked>
                                            <label class="form-check-label font-medium" for="flexRadioDefault1">
                                                Yes, secure my trip, I agree to the <a href="#">Terms & Conditions</a> &
                                                <a href="#">Good Health</a>
                                                terms, and confirm all passengers are
                                                between 2 to 70 years of age

                                            </label>
                                             
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault2" checked>
                                            <label class="form-check-label font-medium" for="flexRadioDefault2">
                                                No, I do not wish to secure my trip
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              </div>
              <div class="col-12 col-md-3">
                <div class="fw-bold mb-3">{{ $t("fareDetContent.fareDetails")}}</div>
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="theme-box-shadow theme-border-radius bg-light">
                      <ul class="p-0">
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex justify-content-between">
                            <span class="font-medium" style="font-weight: 500">
                              {{ $t("fareDetContent.passenger")}}</span
                            >
                          </div>
                        </li>
                        <!-- gst charge fare -->
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex flex-column">
                            <span v-if="adultLength > 0"
                              >{{ adultLength }} x {{ $t("fareDetContent.adult")}}</span
                            ><br v-if="childLength > 0" />
                            <span v-if="childLength > 0"
                              >{{ childLength }} x {{ $t("fareDetContent.child")}}</span
                            ><br v-if="infLength > 0" />
                            <span v-if="infLength > 0"
                              >{{ infLength }} x {{ $t("fareDetContent.infant")}}</span
                            >
                            <!-- </span> -->
                          </div>
                        </li>
                        <!-- grand total charge fare -->
                        <li class="border-bottom p-3 font-medium">
                          <div class="d-flex justify-content-between">
                            <span class="grandText" style="font-weight: 600">
                              {{ $t("fareDetContent.grandTotal")}}</span
                            >
                            <span class="grandPrice">
                              <span class="fw-bold">
                                {{ currency }} {{ totalAmountpassenger }}</span
                              >
                            </span>
                          </div>
                        </li>

                        <li class="border-bottom pb-2 p-3 font-medium">
                        <a class="d-flex align-center justify-content-between theme-text-accent-one"
                          data-bs-toggle="collapse"
                          href="#faresummary"
                          role="button"
                          aria-expanded="false"
                          aria-controls="faresummary">
                          <span class="font-medium fw-bold pb-2"> {{ $t("fareDetContent.fareSummary")}}</span>
                          <span>
                            <i class="bi bi-plus-circle-fill theme-text-accent-two"></i>
                          </span>
                        </a>
                        <div class="collapse border-top pt-3" id="faresummary">
                          <div v-for="(res, index) of getDataResult"
                            :key="index"
                            class="bordeer-top-0 border border-2 border-top-0">
                            <div class="comDetailSection">
                              <div class="paxpricesection">
                                <table class="table table-borderless mb-0">
                                  <thead>
                                    <tr class="fw-500">
                                      <td style="background: #dee2e6;font-size:13px;">{{ $t("fareDetContent.pax")}}</td>
                                      <td style="background: #dee2e6;font-size:13px;">{{ $t("fareDetContent.base")}}</td>
                                      <td style="background: #dee2e6;font-size:13px;">{{ $t("fareDetContent.tax")}}</td>
                                      <td style="background: #dee2e6;font-size:13px;">{{ $t("fareDetContent.perPerson")}}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {{
                                      console.log(res, "priceDetailllll")
                                    }}
                                    <template v-for="(priceDetail, paxindex) in res.priceDetails" :key="paxindex">
                                      <tr v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice"
                                       style="font-weight:400;font-size:13px;">
                                        <td>{{ translatePaxType(priceDetail.pax_type) }}</td>
                                        <td>{{ priceDetail.baseprice }}</td>
                                        <td>{{ priceDetail.taxprice }}</td>
                                        <td>{{ getperpersonPrice(priceDetail) }}</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="border-bottom font-medium">
                        <div class="d-flex justify-content-between py-2 px-4">
                            <span class="font-medium">{{ $t("fareDetContent.basePrice")}}<br />
                              {{ $t("fareDetContent.taxesFees")}}</span>
                            <!-- <span class="font-medium text-end">
                              {{ currency }} {{ baseprice }}<br />
                              {{ currency }} {{ totalTax }}
                            </span> -->
                            <div class="d-flex flex-column">
                              <div class="row">
                                <div class="col-4 ps-0">{{ currency }}</div>
                                <div class="col-8 text-end ps-0">{{ totalbasePassanger }}</div>
                              </div>
                              <div class="row">
                                <div class="col-4 ps-0">{{ currency }}</div>
                                  <div class="col-8 text-end ps-0">{{ totalTaxPassanger }}</div>
                              </div>
                            </div>
                          </div>
                      </li>

                        <li class="border-bottom font-medium">
                          <div class="d-flex text-start">
                            <a
                              class="baggage border-none p-3"
                              style="
                                font-size: 13px;
                                font-weight: 600;
                                color: rgb(13, 110, 253);
                                text-decoration: underline;
                              " @click="getFareRules()"
                            >
                              <span
                                data-bs-toggle="offcanvas"
                                data-bs-target="#staticBackdrop"
                                aria-controls="staticBackdropLabel"
                                >{{ $t("baggageDetContent.contents.baggAndRules") }}</span
                              >
                            </a>
                          </div>
                          <div>
                          <div class="drawer-section">
                            <div
                              class="offcanvas offcanvas-end"
                              data-bs-backdrop="static"
                              tabindex="-1"
                              id="staticBackdrop"
                              aria-labelledby="staticBackdropLabel"
                            >
                              <div
                                class="offcanvas-header d-flex justify-content-between"
                                style="
                                  background: #5293cb !important;
                                  color: #fff !important;
                                "
                              >
                                <h6 class="offcanvas-title" id="staticBackdrop">
                                  {{ $t("baggageDetContent.contents.baggAndRules") }}
                                </h6>
                                <button
                                  type="button"
                                  class="text-reset"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  color="white" @click="closeBaggageRules"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </button>
                              </div>
                              <div class="offcanvas-body pt-0" style="overflow-x:hidden;">
                                 <v-card class="mt-4">
                                  <v-tabs v-model="tab" align-tabs="center" stacked>
                                    <v-tab value="tab-1" class="m-0">
                                      <v-icon
                                        >mdi-bag-checked</v-icon
                                      >
                                      {{ $t("baggageDetContent.contents.baggageHeading") }}
                                    </v-tab>
                                      <v-tab value="tab-2">
                                      <v-icon>mdi-book-cancel-outline</v-icon>
                                      {{ $t("baggageDetContent.contents.cancelRules") }}
                                    </v-tab>
                                  </v-tabs>

                                  <v-card-text class="pt-0">
                                    <keep-alive>
                                      <v-window v-model="tab">
                                <div v-if="tab === 'tab-1'">
                                <h6 class="mt-1">{{ $t("baggageDetContent.contents.baggage") }}:</h6>
                                <div class="p-2 rounded" v-for="(data, index) of roundresult" :key="index">
                                  <div>
                                  <div style="font-size: 15px; font-weight: 500">
                                {{ data.fromTrip }} - {{ data.toTrip }}
                              </div>
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                  <span v-if="data.depBaggage" class="ms-1">
                                    {{ data.depBaggage.PieceAllowance.TotalQuantity || 0 }}

                                    {{ data.depBaggage.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                  </span>
                                  <span v-if="data.BaggageData" class="ms-1">
                                    {{ data.BaggageData.PieceAllowance.TotalQuantity || 0 }}

                                    {{ data.BaggageData.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                   <span v-if="data.depBaggage" class="ms-1">
                                    {{
                                      data.depBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                    }}
                                  </span>
                                  <span v-if="data.BaggageData" class="ms-1">
                                    {{
                                      data.BaggageData.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                    }}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Return" />
                              <div v-if="data.Return" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ data.toTrip }} - {{ data.fromTrip }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-1">
                                <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span> {{ $t("baggageDetContent.contents.checked") }}:</span>
                                  <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                  <span class="ms-1">
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity || 0
                                    }}
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity > 1
                                        ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                    
                                  </span>
                                  <v-icon color="gray" style="color:gray !important;">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}:</span>
                                  <span class="ms-1">
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                    }}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                                </div>
                                <div v-if="multiresult">
                                <div class="p-2 rounded" v-for="(data, index) of multiresult" :key="index">
                                  <div>
                                  <div style="font-size: 15px; font-weight: 500">
                               {{ getdepNameMulti(data.muticityData[0]) }} -
                            {{ getarrNameMulti(data.muticityData[0]) }}
                              </div>
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                   <span class="mt-1">
                                      {{ data.BaggageData1.PieceAllowance.TotalQuantity || 0 }}
                                      {{data.BaggageData1.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                    </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                   <span class=""
                                >{{data.BaggageData1.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight2" />
                              <div v-if="data.Flight2" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[1]) }} -
                            {{ getarrNameMulti(data.muticityData[1]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                   <span class="mt-1">
                                {{ data.BaggageData2.PieceAllowance.TotalQuantity || 0 }}
                                {{data.BaggageData2.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                    </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                   <span class=""
                                >{{data.BaggageData2.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight3" />
                              <div v-if="data.Flight3" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[2]) }} -
                            {{ getarrNameMulti(data.muticityData[2]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                   <span class="mt-1">
                                      {{ data.BaggageData3.PieceAllowance.TotalQuantity || 0 }}
                                      {{data.BaggageData3.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                    </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                   <span class=""
                                >{{data.BaggageData3.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + " " + $t("baggageDetContent.contents.piece") : 1 + " " + $t("baggageDetContent.contents.piece")
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight4" />
                              <div v-if="data.Flight4" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[3]) }} -
                            {{ getarrNameMulti(data.muticityData[3]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> {{ $t("baggageDetContent.contents.checked") }}: </span>
                                   <span class="mt-1">
                                {{ data.BaggageData4.PieceAllowance.TotalQuantity || 0 }}
                                {{data.BaggageData4.PieceAllowance.TotalQuantity > 1 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                    </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>{{ $t("baggageDetContent.contents.carryOn") }}: </span>
                                   <span class=""
                                >{{data.BaggageData4.PieceAllowance
                                    .TotalQuantity > 0 ? $t("baggageDetContent.contents.pieces") : $t("baggageDetContent.contents.piece")}}
                                </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                                </div>
                                </div>
                                </div>
                                <div v-if="tab === 'tab-2'">
                                  <h6 class="mt-2">{{ $t("baggageDetContent.contents.cancelRules") }}:</h6>
                                  <div class="p-2 cancellationRules">
                                    <p class="my-1" v-if="!fareRulesContent">{{ $t("baggageDetContent.contents.CancelRulesContent") }}</p>
                                    <div v-else v-html="fareRulesContent"></div>
                                  </div>
                                </div>
                                </v-window>
                                </keep-alive>
                              </v-card-text>
                            </v-card>
                              </div>
                            </div>
                          </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row" style="display: none">
                  <div class="col-12">
                    <div
                      class="theme-box-shadow theme-border-radius bg-light p-3 font-small"
                    >
                      Cancellation &amp; Date change charges
                      <a href="#" class="">More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- button section -->
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-effect btn-book">
                  {{ $t("flightDetContent.contents.continue") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- page footer section -->
    </v-form>
  </div>

  <v-dialog v-model="dialog1" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog3Content.text1") }}</h3>
        <h5>{{ $t("errorContent.dialog3Content.text2") }}</h5>
        <h6>{{ $t("errorContent.dialog4Content.text3") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="tryAgain1()" color="#63AB45">{{ $t("errorContent.buttonText.tryAgain") }}</v-btn>

          <v-btn to="/" color="#63AB45">{{ $t("errorContent.buttonText.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog3Content.text1") }}</h3>
        <h5>{{ $t("errorContent.dialog3Content.text2") }}</h5>
        <h6>{{ $t("errorContent.dialog4Content.text3") }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="tryAgain2()" color="#63AB45">{{ $t("errorContent.buttonText.tryAgain") }}</v-btn>

          <v-btn to="/" color="#63AB45">{{ $t("errorContent.buttonText.returnHome") }}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <!-- <div v-if="dataloader">
    <dataloader></dataloader>
  </div> -->

  <div v-if="!pageloader">
    <pageLoader></pageLoader>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import country1 from "@/coundtrycode.json";
// import dataloader from "@/components/dataLoder.vue";
import pageLoader from "@/components/loaderComponent.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    pageLoader,
  },
  data() {
    const monthListAll = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1); // Use any year, here I used 2000
      const monthName = date
        .toLocaleString("default", { month: "short" })
        .substring(0, 3);
      return monthName;
    });

    return {
      shoppingid:"",
      selectId:"",
      tab:null,
      fareRulesContent:"",
      isFareSummary: false,
      checkOutPage: true,
      dataloader: true,
      pageloader: false,
      dialog1: false,
      dialog2: false,
      airlogodata: [],
      airlines_LogoData:[],
      portal: false,
      countrycode: [],
      portal_shopId: "",
      portal_offId: "",
      portal_Id:"",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "23%",
      booking_Id: "",
      formCheckBox: false,
      checkForm: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,
      airlogoApi:"",

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight1: [],
      dobAdultError: false,
      // dobChildError: false,
      // dobInfrantError: false,

      dataLink: "",

      bookingviewApi: "",

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      local_id: "",
      Localoneway_id: null,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      segmentList: [],

      monthListOriginal: [...monthListAll],
      monthList: [...monthListAll],
      storeMonthList: [],
      faresRulesApi:"",
    };
  },
  methods: {

    getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        // console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        // console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        // console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    closeBaggageRules(){
      this.tab = 'tab-1'
    },
    toggleFareSummary() {
      this.isFareSummary = !this.isFareSummary;
    },
    getsegment() {
      if (this.roundresult) {
        this.roundresult.forEach((v) => {
          if (v.Depature) {
            v.Depature.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Return) {
            v.Return.forEach((e) => {
              this.segmentList.push(e);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }

      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

    inputHandle(data, index) {
      if (data.Type == "Adult") {
        if (data.dateDob.year.length < 4) {
          this.adultData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Child") {
        if (data.dateDob.year.length < 4) {
          this.childData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Infrant") {
        if (data.dateDob.year.length < 4) {
          this.infrantData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      }
    },

    inputChange(data, index) {
      // console.log(data,index,'data,indexdata,indexdata,index')

      const enteredYear = data.dateDob.year;

      if (data.Type == "Adult") {
        this.adultData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....1");
      } else if (data.Type == "Child") {
        this.childData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....2");
      } else if (data.Type == "Infrant") {
        this.infrantData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        let currentDate = moment(
          this.segmentList[this.segmentList.length - 1].Departure.Date
        ).format("YYYY-MMM-DD");
        let maximumDate = new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate() - 15
        );
        let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");

        console.log(currentDate, "currentDate...", maxMonth, "maxMonth...");

        if (enteredYear == new Date(maximumDate).getFullYear()) {
          const maxMonthIndex = this.monthListOriginal.indexOf(maxMonth);
          this.monthList = [...this.monthListOriginal];
          this.monthList.splice(maxMonthIndex + 1);
          console.log(this.monthList, "this.monthListthis.monthList....3");
        } else {
          this.monthList = [...this.monthListOriginal];
          console.log(this.monthList, "this.monthListthis.monthList.....4");
        }
      }
    },

    yearRules(Type, index) {
      return [
        (v) => !!v || "Please enter the Year",
        (v) => /^\d+$/.test(v) || "Please enter a valid Year",
        (v) => {
          const currentYear = new Date(
            this.segmentList[0].Departure.Date
          ).getFullYear();
          const enteredYear = parseInt(v, 10);

          let minYear, maxYear;

          if (Type === "Adult") {
            minYear = currentYear - 100;
            maxYear = currentYear - 12;

            if (v.length === 4) {
              console.log(enteredYear, "enteredYearenteredYear");
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.adultData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Child") {
            this.segmentList.forEach((v) => {
              if (this.segmentList.length - 1) {
                maxYear = new Date(v.Departure.Date).getFullYear();
              }
            });

            maxYear = currentYear - 2;
            minYear = maxYear - 10;

            console.log(minYear, "minYearminYearminYear");

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.childData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Infrant") {
            let currentDate = moment(
              this.segmentList[this.segmentList.length - 1].Departure.Date
            ).format("YYYY-MMM-DD");
            let maximumDate = new Date(
              new Date(currentDate).getFullYear(),
              new Date(currentDate).getMonth() - 1,
              new Date(currentDate).getDate()
            );
            let maxDate = moment(maximumDate, "YYYY-MMM-DD").format(
              "YYYY-MMM-DD"
            );

            maxYear = new Date(maxDate).getFullYear();
            minYear = maxYear - 2;

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.infrantData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          }
        },
      ];
    },

    monthRules(Type, index) {
      return [
        // () => (!!this.adultData[index].dateDob.year || !!this.childData[index].dateDob.year || !!this.infrantData[index].dateDob.year) || `Please enter the Year first`,
        () => {
          if (Type === "Adult") {
            if (!this.adultData[index].dateDob.year) {
              return (
                !!this.adultData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Child") {
            if (!this.childData[index].dateDob.year) {
              return (
                !!this.childData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Infrant") {
            if (!this.infrantData[index].dateDob.year) {
              return (
                !!this.infrantData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          }
        },

        (v) => !!v || `Please enter the Month`,
      ];
    },

    dateRules(data, index) {
      return [
        () => {
          if (data.Type === "Adult") {
            if (
              !this.adultData[index].dateDob.year &&
              !this.adultData[index].dateDob.month
            ) {
              return (
                (!!this.adultData[index].dateDob.year &&
                  !!this.adultData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Child") {
            if (
              !this.childData[index].dateDob.year &&
              !this.childData[index].dateDob.month
            ) {
              return (
                (!!this.childData[index].dateDob.year &&
                  !!this.childData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Infrant") {
            if (
              !this.infrantData[index].dateDob.year &&
              !this.infrantData[index].dateDob.month
            ) {
              return (
                (!!this.infrantData[index].dateDob.year &&
                  !!this.infrantData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          }
        },

        (v) => /^\d+$/.test(v) || "Please enter the date",
        (v) => (v >= 1 && v <= 31) || "Please enter a valid date",
        (v) => /^\d{2}$/.test(v) || 'format like "05"',

        (v) => {
          const dataDob =
            data.Type === "Adult"
              ? this.adultData[index].dateDob
              : data.Type === "Child"
              ? this.childData[index].dateDob
              : this.infrantData[index].dateDob;

          const year = dataDob.year;
          const month = dataDob.month;

          if (year && month) {
            console.log(year, month, "yearyearyearyear");
            const monthIndex = this.monthList.indexOf(month);
            console.log(monthIndex, "monthIndexmonthIndexmonthIndex");
            console.log(this.monthList, "monthListmonthListmonthList");
            const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate();
            console.log(lastDayOfMonth, "lastDayOfMonthlastDayOfMonth");
            return (
              (v >= 1 && v <= lastDayOfMonth) || `Invalid date for ${month}`
            );
          }

          return true;
        },

        (v) => {
          let currentDate = moment(
            this.segmentList[this.segmentList.length - 1].Departure.Date
          ).format("YYYY-MMM-DD");
          let maximumDate = new Date(
            new Date(currentDate).getFullYear(),
            new Date(currentDate).getMonth(),
            new Date(currentDate).getDate() - 15
          );
          let formatDate = moment(maximumDate, "YYYY-MMM-DD").format(
            "YYYY-MMM-DD"
          );
          let maxYear = new Date(formatDate).getFullYear();
          let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");
          let maxDate = new Date(formatDate).getDate();
          maxDate = maxDate.toString().padStart(2, "0");
          console.log(maxDate, "maxDatemaxDatemaxDate..111.");
          console.log(
            currentDate,
            "currentDate....",
            maximumDate,
            "maximumDatemaximumDatemaximumDate..."
          );

          if (data.Type === "Infrant") {
            let normalDate = v >= 1 && v <= 31;
            let validDate = v >= 1 && v <= maxDate;

            if (
              maxYear == data.dateDob.year &&
              maxMonth == data.dateDob.month
            ) {
              return (
                validDate ||
                `Allow travel before ${maxMonth},${maxDate} for infrants`
              );
            } else {
              return normalDate;
            }
          }
        },
      ];
    },

    tryAgain1() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....1");
      this.dialog1 = false;
      this.portelDataConvert();
    },
    tryAgain2() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....2");
      this.dialog2 = false;

      if (this.local_id) {
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id1: `${this.local_id}` },
        });

        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      } else {
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id1: `${this.Localoneway_id}` },
        });

        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      }
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdepName1(data) {
      // console.log(data, "getdepcode1");
      return data;
      // return data.split("(")[0];
    },
    getdepNameMulti(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },
    getarrName1(data) {
      return data;
      // return data.split("(")[0];
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    // add() {
    //     for (let i = 0; i < this.adultData.length; i++) {
    //         if (this.adultData.length < 1) {
    //             this.adultData.push({
    //                 fname: "",
    //                 last: "",
    //                 middle: "",
    //                 year: "",
    //                 date: "",
    //                 month: "",
    //                 gender: "",
    //                 salutation: "",
    //                 ffbnumber: false,
    //             })
    //         }
    //     }
    // },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobChildError = false;
        } else {
          v.clicked1 = false;
          this.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          this.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      console.log(this.adult, "this.adultthis.adult");
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.length < this.adult) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
            isYearValid: false,
          });
        } else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            isYearValid: false,
            // dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            isYearValid: false,
            // dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    getadult() {
      this.pageloader = true;
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.pageloader = true;
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      this.pageloader = true;
      // let res = this.local_id.split("-");
      // let res1 = res[res.length - 1];
      // let data_pass = [];
      // data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      // this.adultLength = data_pass.Adult;
      // this.childLength = data_pass.Child;
      // this.infLength = data_pass.Inf;
      // this.adult = data_pass.Adult;
      // this.child = data_pass.Child;
      // this.inf = data_pass.Inf;
      // console.log(data_pass, "data_pass");

      // let totalpass = [];
      // totalpass = this.adultLength + this.childLength + this.infLength;
      // console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;

        let totalpass = [];
        totalpass = this.adultLength + this.childLength + this.infLength;
        console.log(totalpass, "totalpass");
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },

    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },

    submitform() {
      this.dataLoader = true;
      this.pageloader = false;
      console.log( this.dataLoader,"dataLoaderdataLoader...dataLoaderdataLoader");
      
      // this.fareinsert();
      let contact = {
        contactCheck: this.contactCheck,
        couponsCheck: this.couponsCheck,
      };
      localStorage.setItem("Travelcontact", JSON.stringify(contact));
      this.passengersDatas.adultDatas = this.adultData;
      this.passengersDatas.childDatas = this.childData;
      this.passengersDatas.infrantDatas = this.infrantData;

      // if ( this.formCheckBox) {
      console.log(this.valid1, "ggghghghghg.....");
      this.datenumber = true;

      if (this.portal) {
        this.dataLoader = false;

        let uniDataId = `${"env"}-${this.local_id}`;
        localStorage.setItem(
          `${uniDataId}`,
          JSON.stringify(this.portalResData)
        );
        if (this.roundresult.length > 0) {
          localStorage.setItem(
            `${"env"}-${this.local_id}`,
            JSON.stringify(this.portalResData)
          );

          this.$router.push({
            path: "/flightinfo",
            query: { pass_id: uniDataId },
          });
        } else if (this.onewayresult.length > 0) {
          localStorage.setItem(
            `${"env"}-${this.local_id}`,
            JSON.stringify(this.portalResData)
          );
          this.$router.push({
            path: "/flightinfo",
            query: { pass_id: uniDataId },
          });
        } else if (this.multiresult.length > 0) {
          localStorage.setItem(
            `${"env"}-${this.local_id}`,
            JSON.stringify(this.portalResData)
          );
          localStorage.setItem(
            "env-local_id",
            JSON.stringify(this.portalResData)
          );
          this.$router.push({
            path: "/flightinfo",
            query: { pass_id: uniDataId },
          });
        }
      } else {
        this.dataLoader = true;
        console.log(this.dataLoader, "dataLoaderdataLoader....1");

        setTimeout(() => {
          this.dataLoader = false;
          console.log(this.dataLoader, "dataLoaderdataLoader....2");

          if (this.Localoneway_id) {
            this.$router.push({
              path: "/flightinfo",
              query: { pass_id1: this.Localoneway_id },
            });
          } else if (this.local_id.includes("multi")) {
            this.$router.push({
              path: "/flightinfo",
              query: { pass_uid: this.local_id },
            });
          } else if (this.local_id) {
            this.$router.push({
              path: "/flightinfo",
              query: { pass_id1: this.local_id },
            });
          } else {
            console("Not....working....");
          }
        }, 3000);
      }
      // } else {
      //   this.dobAdultError = true;
      //   this.dobChildError = true;
      //   this.dobInfrantError = true;
      //   this.datenumber = true;

      //   if (!this.formCheckBox) {
      //     this.checkForm = true;
      //   }

      //   return this.valid1;
      // }

      localStorage.setItem(
        "TravelerDetails",
        JSON.stringify(this.passengersDatas)
      );
      localStorage.setItem(
        "passengerContact",
        JSON.stringify(this.paxContacts)
      );
      localStorage.removeItem("TravelerDetails")
    },
    async getFareRules(){
       let fareRequest = {
      request_type: "farerules",
      shopping_response_id: this.shoppingid,
      offer_id: this.selectId ,
      portal_id: this.portal_Id,
      portal_domain: this.portal_domain
    }
    console.log(fareRequest,'fareRequestssssss')
    await axios.get(this.faresRulesApi, {
				// headers: {
				// 	"Content-Type": "application/JSON",
				// },
        params:fareRequest 
			})
				.then((response) => {

					this.fareRulesContent = response.data
					// console.log(response.data, "fareRulesContentfareRulesContent.....")

				})

				.catch((error) => {
					console.log(error, 'erroroor.....')
				})
    },

    async portelDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: "US",
        api_env: "CERT",
        request_type: "user_booking_view",
        portal_id: this.portal_Id,
        portal_domain:this.portal_domain
      };
      console.log(userBooking, this.bookingviewApi, "userBookinguserBooking");
      await axios
        .post(this.bookingviewApi, userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          console.log(responce.data, "responce.dataresponce.data5555555555");
          if (responce.data.view_response == "fail") {
            setTimeout(() => {
              this.dialog1 = true;
            }, 3000);
            console.log("dialog1111");
          } else {
            let result = responce.data;
            this.portalData.push(result);
            console.log(this.portalData, "this.portalDatathis.portalData");
            this.checkOutPage = true;
            this.dataloader = false;
            this.pageloader = true;
            console.log("dialog11114444444444444");
          }
        })
        .catch((err) => {
          console.log(err, "ppppp5555555");
          setTimeout(() => {
            this.dialog1 = true;
          }, 3000);
        });

      if (this.portalData.length > 0) {
        this.dataloader = false;
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          // console.log(flightsegment, "flightsegment");
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF") {
                inf = t.PassengerQuantity;
              }
            });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              if (index == 0) {
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 2) {
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 3) {
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });
          console.log(flightsegment, "this.flightsegment");

          if (this.offerData1.length > 0) {
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                  this.Flight1.push(seg);
                }
              });
            }
          }

          if (this.offerData2.length > 0) {
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                }
              });
            }
          }
          // console.log(this.offerData3, "this.offerData3this.offerData3");
          if (this.offerData3.length > 0) {
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          if (this.offerData4.length > 0) {
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          for (let i = 0; i < this.airlogodata.length - 1; i++) {
            for (let j = 0; j < flightsegment.length - 1; j++) {
              if (
                flightsegment[j].MarketingCarrier.AirlineID ==
                this.airlogodata[i].id
              ) {
                flightsegment[j].MarketingCarrier.logo =
                  this.airlogodata[i].logo;
              }
            }
          }

          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];
          let cabinType = [];

          console.log($data, "ppppppppppkeerthi");

          $data.forEach((v, i) => {
            console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            if (s.ReqCurrency == "USD") {
              currency.push("US$");
            } else if (s.ReqCurrency == "CAD") {
              currency.push("CA$");
            } else {
              currency.push("US$");
            }
            ownername.push(s.OwnerName);
            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }

            if (s.CabinType) {
              cabinType =
                s.CabinType.charAt(0).toUpperCase() +
                s.CabinType.slice(1).toLowerCase();
            } else {
              cabinType =
                this.portalData[i].SearchClass.charAt(0).toUpperCase() +
                this.portalData[i].SearchClass.slice(1).toLowerCase();
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);
          });

          console.log(cabinType, "cabinTypecabinTypecabinType");

          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].currency = currency[i];
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].Return = this.Flight2[i];
            this.portalResData[i].Flight1 = this.Flight1[i];
            this.portalResData[i].Flight2 = this.Flight2[i];
            this.portalResData[i].Flight3 = this.Flight3[i];
            this.portalResData[i].Flight4 = this.Flight4[i];
            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].Depaturejourney = source1[i];
            this.portalResData[i].Returnjourney = source2[i];
            this.portalResData[i].Flight1JourneyTime = source1[i];
            this.portalResData[i].Flight2JourneyTime = source2[i];
            this.portalResData[i].Flight3JourneyTime = source1[i];
            this.portalResData[i].Flight4JourneyTime = source2[i];
            this.portalResData[i].cabinClass = cabinType;
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
          }

          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData..."
          );
          if (this.offerData3.length > 0) {
            this.multiresult = this.portalResData;
            // this.itineraryInfo = true;
          } else if (this.offerData2.length > 0) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
          } else if (this.offerData1.length > 0) {
            this.onewayresult = this.portalResData;
            this.itineraryInfo1 = true;
          }

          this.portalResData.forEach((v) => {
            console.log(v, "ppppp");
            this.local_id = v.selectId;
            this.currency = v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
            this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
            this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );
        }
      } else {
        console.log("flightdata");
      }
    },

     async airlines_func() {

      await axios
        .get(this.airlogoApi.url, {
          headers: {
            "Content-Type": this.airlogoApi.type,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airlines_LogoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },


    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.bookingviewApi =
          getConfigData1.payload.portal_configuration.API_endpoints.meta_get_itinerary;
          this.portal_Id = getConfigData1.payload.portal_configuration.portal_id;
          this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
          this.airlogoApi = getConfigData1.payload.portal_configuration.content_data.airlines_logo;
          this.faresRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;

        // console.log(this.bookingviewApi,'bookingviewApibookingviewApibookingviewApi')
      }
    },
    translateClass(data){
      if(data == "Economy"){
        return this.$t("formsContents.options.economy");
      }
      else if(data == "Business"){
        return this.$t("formsContents.options.business");
      }
      else if(data == "First Class"){
        return this.$t("formsContents.options.firstClass");
      }
      else if(data == "Premium Economy"){
        return this.$t("formsContents.options.premiumEconomy");
      }
      else{
        return this.$t("formsContents.options.economy");
      }
    },
    translateRefund(data){
      if(data == "Refundable"){
        return this.$t("searchPageContent.itinContent.refund")
      }
      else if(data == "Non Refundable"){
        return this.$t("searchPageContent.itinContent.nonRefund")
      }
    },
    translatePaxType(data){
      if(data == "Adult"){
        return this.$t("formsContents.options.adult")
      }
      else if(data == "Child"){
        return this.$t("formsContents.options.child")
      }
      else if(data == "Inf(lap)"){
        return this.$t("formsContents.options.infant")
      }
    }
  },
  computed:{
    getDataResult() {
      if (this.roundresult && this.roundresult.length > 0) {
        return this.roundresult;
      } else if (this.onewayresult && this.onewayresult.length > 0) {
        return this.onewayresult;
      } else if (this.multiresult && this.multiresult.length > 0) {
        return this.multiresult;
      }

      return [];
    },
  },

  watch: {
    "$i18n.locale": {
      handler: function () {
        this.translateClass();
        this.translateRefund();
        this.translatePaxType();
      },
      deep: true,
    },
    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          // console.log(newData, 'newDatanewDatanewDatanewDatanewDatanewData')
          newData.forEach((data) => {
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            if (data.salutation !== "Mstr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
        newData.forEach((data) => {
          if (data.salutation !== "Mstr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }

          if (data.dateDob.date) {
            // console.log(data.dateOfBirth.date,'newDatanewData')
            if (data.dateDob.year && data.dateDob.month && data.dateDob.date) {
              const monthIndex = this.monthList.indexOf(data.dateDob.month);
              const convertDob = new Date(
                parseInt(data.dateDob.year),
                monthIndex,
                parseInt(data.dateDob.date)
              );

              const formatDate = moment(convertDob).format("YYYY-MMM-DD");

              data.dateOfBirth = formatDate;

              // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
            }
          }
        });
      },
    },

    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },

  mounted() {
    //  window.scrollTo(0, 0);
    // this.$refs.topElement.focus();
    // window.scrollY = 0;
    // this.$refs.topElement.scrollIntoView();
    window.addEventListener('load', () => {
      window.scrollTo(0, 0);
    });
    
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });

    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
  },

  created() {
    this.getConfig();
    this.airlines_func();

    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      console.log("working....11111");
      this.$router.push("/");
    }

    this.local_id = this.$route.query.pass_uid;
    this.dataLink = location.href;
    this.Localoneway_id = this.$route.query.pass_id1;
    console.log(this.local_id, "uid....1");
    console.log(this.Localoneway_id, "this.local_idthis.local_id888888888888");
    // console.log(this.dataLink, "data_idthisdata8");

    //     if (!this.$route.query.pass_uid) {

    // }

    if (this.dataLink.includes("env")) {
      if (this.dataLink.includes("env")) {
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.airlogodata = this.airlines_LogoData;
        this.portelDataConvert();
        this.checkOutPage = false;
        setTimeout(() => {
          this.getsegment();
        }, 2000);
      }
    } else if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid, "uid");

      if (onewaydata_uid) {
        this.roundresult.push(onewaydata_uid);
        this.dataloader = this.roundresult ? false : true;
        this.getadult();
        this.itineraryInfo1 = true;
        this.pageloader = true;

        console.log(this.roundresult, "onewayresult.....onewayresult");

        setTimeout(() => {
          this.getsegment();
        }, 2000);
      } else if (this.Localoneway_id !== onewaydata_uid) {
        console.log("onewayData");
        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      }
    } else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          this.dataloader = this.multiresult ? false : true;
          this.multiData();
          this.pageloader = true;
          console.log(data_uid, "multicitymulticitymulticity");
          setTimeout(() => {
            this.getsegment();
          }, 2000);
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          this.dataloader = this.roundresult ? false : true;
          this.getadult();
          this.itineraryInfo = true;
          this.pageloader = true;

          setTimeout(() => {
            this.getsegment();
          }, 2000);
        } else if (this.local_id !== data_uid) {
          console.log("dialog1dialog1dialog2");

          setTimeout(() => {
            this.pageloader = false;
            this.dialog2 = true;
          }, 5000);
        }
      }
    } else {
      // console.log('redirectingggg...')
      //   this.$router.push("/");
      // setTimeout(() => {
      //   console.log('dialog1dialog1dialog2')
      //         this.pageloader = false;
      //         this.dialog1 = true;
      //       }, 5000);
    }
    if(this.roundresult.length > 0){
      this.shoppingid = this.roundresult[0].ShoppingResponseId
      this.selectId = this.roundresult[0].selectId
    }
    else if(this.multiresult.length > 0){
      this.shoppingid = this.multiresult[0].ShoppingResponseId
      this.selectId = this.multiresult[0].selectId
    }
  },
};
</script>

<style scoped>
@import "@/assets/main.css";

/* extra css */
>>> .bi-clock::before {
  font-size: 17px;
}
>>> .bi-info-circle-fill::before {
  font-size: 17px;
}

.datePickerSection .yearPick,
.monthPick,
.datePick {
  width: 175px;
}

>>> .v-field--active .v-label.v-field-label {
  background: #f8f9fa;
}

>>> .datePickerSection .yearPick .v-field {
  /* border-radius: 0 !important; */
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

>>> .datePickerSection .monthPick .v-field {
  border-radius: 0 !important;
  padding: 0px !important;
}

>>> .datePickerSection .datePick .v-field {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
>>> .v-field__input {
  padding-top: 0px !important;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 5px;
}
>>> .v-field__field {
  height: 40px;
  background-color: white;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}
>>> .v-field__outline {
  --v-field-border-width: 0.5px !important;
  --v-field-border-opacity: 0.02 !important;
  border-radius: 10px;
}
>>> .v-field__append-inner {
  background-color: white !important;
  border: 1px solid #dee2e6 !important;
}
>>> .yearPick .v-field__field {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
>>> .datePick .v-field__field {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
>>> .monthPick .v-field__field {
  border-right: none !important;
}
>>> .monthPick .v-field__append-inner {
  border-left: none !important;
}
.font-medium {
  font-weight: 500;
}
.font-small,
.fw-normal {
  font-weight: 500 !important;
}
ul {
  margin-bottom: 0px !important;
}
.depature .airline {
  font-size: 12px;
  font-weight: 500;
}
@media screen and (max-width: 430px) {
  .depature .airline {
    font-size: 11px;
    font-weight: 500;
  }
}
.grandText .grandPrice{
  font-size:1rem !important;
}
@media screen and (max-width:1200px){
  .grandText .grandPrice{
    font-size:16px !important;
  }
}
/* .baggage:hover{
  background:none !important;
} */
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule h2{
  font-size:17px !important;
  font-weight:700 !important;
}
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule p{
  overflow-wrap: break-word !important;
  inline-size: 58%;
}
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule ul li{
  overflow-wrap: break-word !important;
  inline-size: 60%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}
.drawer-section .offcanvas{
  width:35%;
}
@media screen and (max-width:480px) {
  .flight-search{
    padding:0.6rem 0 2.2rem !important;
  }
  .drawer-section .offcanvas{
    width:100%;
  }
}
>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #63AB45;
}
>>>.v-tabs--density-default.v-tabs--stacked{
      --v-tabs-height: 58px;
}
>>>.v-tabs--fixed-tabs .v-slide-group__content > *:last-child, .v-tabs--align-tabs-center .v-slide-group__content > *:last-child{
  margin-inline-end: inherit;
}
.mx-5{
    margin-left:3rem !important;
    margin-right:3rem !important;
}
a{
    color:black;
}
.offcanvas-title{
  color:white;
}
.table > :not(caption) > * > *{
  padding:5px !important;
}
</style>
