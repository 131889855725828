<template>

<div class="content-section" v-if="!pageloader">
        <!-- flight result page - oneway start -->
        <div class="review-flight py-5">
            <div class="container">
                <div class="row myBookingCard">
                    <div class="col-12 col-md-6 offset-md-3">
                        <!-- travel insurance section -->
                        <div class="theme-box-shadow theme-border-radius theme-bg-white p-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="flex-shrink-0">
                                    <div class="theme-bg-primary p-3 rounded-circle">
                                        <i class="bi bi-journal-text lh-1 fs-4"></i>
                                    </div>

                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="fs-4 fw-bold">{{ $t("viewBookingContent.heading") }}</span>
                                    <!-- <p class="font-medium mb-0 theme-text-accent-one">Sign in and Get lots of
                                        Cashback Rewards and
                                        Discount
                                    </p> -->
                                </div>
                            </div>
                            <div class="col-12">

                                <v-form  v-model="valid" ref="form" @submit.prevent="bookinglist">
                                <!-- <form class="needs-validation" novalidate> -->
                                    <div class="mb-1">
                                        <label for="exampleInputEmail1" class="form-label">{{ $t("viewBookingContent.labels.bookId") }}</label>
                                        <!-- <v-text-field v-model="loginuser.userEmail" :rules="eMail" 
                                    variant="outlined" placeholder="Email"
                                    style="height:55px;font-size: 15px !important"></v-text-field> -->
                                    <v-text-field :placeholder="this.$t('viewBookingContent.labels.bookId')" v-model="bookid" 
                                    :rules="[(v) => !!v || 'Enter the Booking ID']" variant="outlined"></v-text-field>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">{{ $t("viewBookingContent.labels.lastName") }}</label>
                                        <!-- <v-text-field v-model="loginuser.userPass" :rules="uPass"
                                    placeholder="password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="height:55px;font-size: 15px !important"></v-text-field> -->
                                    <v-text-field  :rules="[(v) => !!v || 'Enter the Last Name']" v-model="lastname"
                                    variant="outlined" :placeholder="this.$t('viewBookingContent.labels.lastName')" @input="capitalizeLastName" ></v-text-field>
                                    </div>
                                    <div class="mb-3 mx-auto">
                                        <button type="submit" class="btn btn-effect btn-book px-5 min-h38" :class="{'pe-none' : myBookLoad}">
                                            <v-progress-circular v-if="myBookLoad" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !myBookLoad ? $t("viewBookingContent.buttons.submit") : '' }}</button>
                                        
                                        <span class="font-medium">
                                            <span class="ms-3 me-2 text-uppercase">{{ $t("viewBookingContent.buttons.or") }}</span>
                                            <a href="/" class="">{{ $t("viewBookingContent.buttons.returnHome") }}</a>
                                        </span>
                                    </div>
                                <!-- </form> -->

                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- <div v-if="overlay">
    <dataloader></dataloader>
  </div> -->

  <div v-if="pageloader">
    <pageLoader></pageLoader>
  </div>

</template>

<script>
import moment from "moment";
import axios from "axios";
// import dataloader from "@/components/dataLoder.vue";
import userData from "@/airport_icao.json";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import pageLoader from "@/components/loaderComponent.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
  components: {
    // dataloader,
    // VueDatePicker,
    pageLoader,
  },
  data() {
    return {
      myBookLoad: false, 
      bookid: "",
      bookname:"",
      lastname: "",
      retreivebooking: {
        request_type: "retreive_booking",
        booking_id: "1234qwert",
        pnr: "asddf12",
        last_name: "ldkdf",
        portal_id: "port5678",
      },
      dialog: false,
      valid: false,
      pageloader: true,
      dateFormat: "dd MMM yyyy",
      overlay: false,
      mybooking: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      bookstatus: "Cancel Booking",
      requstup: false,
      datechange: false,
      dedate1: "",
      dedate2: "",
      dedate3: "",
      dedate4: "",
      retriveData: [],
      itineryData: [],
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      bookingTime: "",
      searchcabintype: "",
      countrycode: "",
      modifyBooking:"",
      airlogoApi:"",
      airline_logoData:[],
    };
  },
  methods: {
    capitalizeLastName() {
      this.lastname =
        this.lastname.charAt(0).toUpperCase() + this.lastname.slice(1);
    },
    circle() {
      console.log(this.$route.query.Booking_id, "this.$route.query.Booking_id");
      if (this.$route.query.Booking_id) {
        this.$router.push("/dashboard");
      } else {
        this.dialog = false;
      }
    },
    modify() {
      this.dialog1 = true;
    },
    getdob($event) {
      return moment($event).format("ddd, DD MMM'YY");
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].depart_airport;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_airport;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode1(data) {
      return data[0].depart_airport;
    },
    getarrcode1(data) {
      return data[data.length - 1].arrival_airport;
    },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    seleceprint() {
      const printContent = document.getElementById("printMe");
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    },

    bookinglist() {
      this.myBookLoad = true;
      if (this.valid) {
        this.overlay = true;
        // this.retreivebooking.booking_id = this.bookid;
        // this.retreivebooking.last_name = this.lastname;
        let obj = {
              booking_Id:this.bookid,
              last_name:this.lastname,
         
            }
            
            let booking_details = JSON.stringify(obj)


        setTimeout(() => {
          this.dialog3 = true;
          this.overlay = false;
            this.$router.push({ path: "/bookingView", query: { booking_id: booking_details }, })
              // this.$router.push({ path: "/search", query: rountdetail });
            this.myBookLoad = false;
        },2000)
      }
      else{
        this.myBookLoad = false;
        return this.valid;
      }

    },
    bookingretrive() {
      this.airlogodata = this.airline_logoData;
      this.arrivalData = [];
      this.depatureData = [];
      this.retriveData.forEach((v) => {
        console.log(v, "dtaswww");
        this.baseprice = v.fare_info.price_per_person
        this.currency = v.fare_info.itin_currency;
        this.totalprice = v.fare_info.itin_total_fare;
        this.passengerDetail = v.pax_info;
        this.itineryData = v.itinerary;
        this.contactnum = v.contact_info.paxphone;
        this.passEmail = v.contact_info.paxemail;
        this.tripId = v.booking_id;
        this.bookingStatus = v.status;
        this.bookingTime = moment(v.booking_at).format("YYYY-MM-DD HH:MM");
        this.searchcabintype = v.search_cabin_type;
        this.countrycode = v.contact_info.country_code;

        for (let i = 0; i < this.airlogodata.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.airlogodata[i].id == a.carrier) {
              a.logo = this.airlogodata[i].logo;
              a.flightname = this.airlogodata[i].name;
            }
          });
        }

        console.log(this.datareurn, "this.datareurnthis.datareurn");

        Object.keys(userData).forEach((icao) => {
          const airport = userData[icao];
          if (airport.iata !== "") {
            this.fromAirports.push({
              iata: airport.iata,
              icao: airport.icao,
              places: `${airport.name}`,
            });
          }
        });

        for (let i = 0; i < this.fromAirports.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.fromAirports[i].iata == a.depart_airport) {
              a.depAirportName = this.fromAirports[i].places;
            }
            if (this.fromAirports[i].iata == a.arrival_airport) {
              a.arrAirportName = this.fromAirports[i].places;
            }
          });
        }
        this.itineryData.forEach((j) => {
          if (j.flight_line == 2) {
            this.triptype = "ROUND TRIP";
          } else {
            this.triptype = "ONEWAY TRIP";
          }
        });

        this.itineryData.forEach((s) => {
          if (s.flight_line == 2) {
            this.datareurn = true;
            this.arrivalData.push(s);
          } else if (s.flight_line == 1) {
            this.depatureData.push(s);
          }
        });
        console.log(this.depatureData, "this.depatureData");
        console.log(this.arrivalData, "this.depatureData");

      });
      this.gettimedep()
    },

    gettimedep() {
    // alert('pppppp');
    let result = [];
    let result1 = [];
    if(this.depatureData.length>0){

    

    for (let i = 0; i < this.depatureData.length - 1; i++) {
        console.log(this.depatureData[i].depart_datetime, 'popppopoppopop');

        const time1 = new Date(this.depatureData[i + 1].depart_datetime);
        const time2 = new Date(this.depatureData[i].arrival_datetime);

        const diffInMilliseconds = Math.abs(time1 - time2);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        result.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);
    }
    
    this.depatureData.forEach((v,j)=>{
      v.overlayTime=result[j]
    })
  }


  if(this.arrivalData.length>0){

    

for (let i = 0; i < this.arrivalData.length - 1; i++) {
    console.log(this.arrivalData[i].depart_datetime, 'popppopoppopop');

    const time1 = new Date(this.arrivalData[i + 1].depart_datetime);
    const time2 = new Date(this.arrivalData[i].arrival_datetime);

    const diffInMilliseconds = Math.abs(time1 - time2);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    result1.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);
}

this.arrivalData.forEach((v,j)=>{
  v.overlayTime=result1[j]
})
}


    console.log( this.depatureData, 'ayhaiahjhashuh');
},






    modifyreq(data) {
      console.log(data, "ppppp");
      this.overlay = true;
      let result = "";
      if (data == "Cancel Booking") {
        result = "cancel";
      } else if (data == "Date Change") {
        result = "date_change";
      } else if (data == "Upgrade Booking") {
        result = "upgrade";
      } else if (data == "Request Addons") {
        result = "addon";
      }

      let modify = {
        request_type: "modify_request",
        booking_id: this.bookid,
        modify_request_type: result,
        outbound_date_between: "",
        inbound_date_between: "",
        notes: "test",
      };

      modify.outbound_date_between = `${moment(this.dedate1).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate2).format("YYYY-MM-DD")}`;
      modify.inbound_date_between = `${moment(this.dedate3).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate4).format("YYYY-MM-DD")}`;

      console.log(modify, "modifymodify");

      axios
        .post(this.modifyBooking, modify, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.modify_response == "success") {
            this.overlay = false;
            this.dialog1 = false;
            this.dialog2 = true;
          }

          console.log(response.data.modify_response, "success");
        })
        .catch((error) => {
          console.log(error, "modify");
        });
    },

      async airlineLogo_func() {

      await axios
        .get(this.airlogoApi.url, {
          headers: {
            "Content-Type": this.airlogoApi.type,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },



    getConfig() {

// console.log(this.$store.state.data,'homehoemehojoke...')

// let getConfigData = this.$store.state.data
const getConfigData1 = getConfigDataFromLocalStorage();

if (getConfigData1) {

  this.modifyBooking = getConfigData1.payload.portal_configuration.API_endpoints.modify_booking;
  this.airlogoApi = getConfigData1.payload.portal_configuration.content_data.airlines_logo;

  // this.getConfigFunc();
}

},
  },
  watch: {
    bookstatus(newVal) {
      console.log(newVal, "newVal");
      if (newVal == "Cancel Booking") {
        this.datechange = false;
        this.requstup = false;
      } else if (newVal == "Date Change") {
        this.datechange = true;
        this.requstup = false;
      } else if (newVal == "Upgrade Booking" || newVal == "Request Addons") {
        this.datechange = false;
        this.requstup = true;
      }
    },
  },

  mounted() {
    // this.fetchData();
    document.getElementById("navbar").style.color = "black";
  },

  created() {

    this.getConfig();
    this.airlineLogo_func();

    setTimeout(()=>{
      this.pageloader = false;
    },2000)

    console.log(this.$route.query.Booking_id, "pppppp");
    this.bookid = this.$route.query.Booking_id;
      this.lastname= this.$route.query.last_name;
    if (this.bookid) {
      this.valid = true;
      this.bookinglist();
    }
  },
};
</script>

<style scoped>
.trip {
  justify-content: center;
  display: flex;
  background-color: #f3d8cf;
  padding: 30px;
  font-size: 25px;
  font-weight: 600;
}
.pre-card {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  border-radius: 15px;
}

.mybookCard{
  width: 550px; 
  padding: 40px; 
  height: 280px;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.f-size-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600;
}

.nav-list {
  cursor: pointer;
}

.dropdown-list {
  cursor: pointer;
}

.data-list {
  cursor: pointer;
}

/* .dropdown-list {
   transition: all 0.5s ease-in-out; 
   max-height: 0; 
   overflow: hidden;
   opacity: 0;
 } */

.nav-list:hover .dropdown-list {
  max-height: 500px;
  opacity: 1;
  transition-delay: 0.5s;
}
.book-back {
  background-color: #ff5400;
}
.f-size-16 {
  font-size: 16px;
}
>>> .dp__pointer {
  cursor: pointer;
  font-size: 13px;
}
.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 13px;
    font-weight: 400;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
   font-size: 17px;
   font-weight: 400;
 } */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.f-family {
  font-family: sans-serif;
  font-size: 15px;
}

.Portal {
  display: none;
}
.trip1 {
  justify-content: center;
  display: flex;
  padding: 15px;
}
>>> .v-textarea .v-field--no-label textarea,
.v-textarea .v-field--active textarea {
  opacity: 1;
  height: 50px;
}
/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 480px) {
  .print-btn-2 {
    display: none;
  }

  .mobile-table {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .contion {
    display: block;
  }

  .Portal {
    display: none;
  }

  .Portal {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }

  .print-btn-1 {
    display: none;
  }

  .print-btn-2 {
    display: flex;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
  color: green;
  outline: 1px solid;
  width: 130px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}







.pre-card .btn-search {
  background-color: #002d5b;
  min-height: 56px;
  color: #ffffff;
  min-width: 75px;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.pre-card .btn-search:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: -100%;
  background: #ffc104;
  transition: all 0.2s;
}
.pre-card .btn-search:hover {
  color: #343534;
}
.pre-card .btn-search:hover:before {
  top: 0;
  left: 0;
}










>>>.myBookingCard .v-input__control {
  height: 45px;
}

>>>.myBookingCard .v-field__field {
  height: 45px;
}

>>>.myBookingCard .v-field--focused {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
  border: none !important;
}
>>>.myBookingCard .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
}

>>>.myBookingCard .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-top: 8px;
}


>>>.myBookingCard .v-field__input input::placeholder {
    position: relative;
    top: -10px;
}

>>>.myBookingCard .v-autocomplete .v-field__input input::placeholder {
    position: relative;
    top: 0px;
    
}
.px-5{
    padding-left:3rem !important;
    padding-right:3rem !important;
}
.bi-journal-text{
  color:white;
}
</style>

